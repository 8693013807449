import { Table } from 'antd'
import moment from 'moment'
import { FC, useEffect, useMemo } from 'react'
import { TCountry } from 'src/graphql'
import { useBehaviorMapper } from 'src/hooks'
import { EPaths } from 'src/router'
import { BreadcrumbService } from 'src/services'
import { CountriesStore } from 'src/store'
import { renderColumns } from './columns'

export const CountryConfigHistory: FC = () => {
  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = [
      {
        route: EPaths.COUNTRY_CONFIGURATIONS,
        label: 'Country Configurations'
      },
      {
        route: EPaths.COUNTRY_CONFIG_HISTORY,
        label: 'History'
      }
    ]
    return () => {
      BreadcrumbService.items = []
    }
  }, [])

  const countryList = useBehaviorMapper(CountriesStore.supportedCountries$)
  const dataSource = useMemo(() => {
    return countryList
      .reduce<NonNullable<TCountry['editLogs']>>((acc, cur) => {
        acc = [...acc, ...(cur.editLogs || [])]
        return acc
      }, [])
      .sort(
        (log1, log2) =>
          moment(log2.updateTime).toDate().getTime() -
          moment(log1.updateTime).toDate().getTime()
      )
  }, [countryList])

  return (
    <section className="fx fx-column fx-extend">
      <Table
        bordered
        rowKey="id"
        className="fx-extend"
        dataSource={dataSource}
        columns={renderColumns({ countryList })}
        tableLayout="fixed"
        scroll={{ x: true }}
      />
    </section>
  )
}
