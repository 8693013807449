import { EAccountOffChainPermission, TUser } from 'src/graphql'

export class UserUtils {
  static getFullName(user?: {
    firstName?: string | null
    lastName?: string | null
  }) {
    return [user?.firstName, user?.lastName].filter(Boolean).join(' ')
  }

  static isAdminBusiness(user?: TUser) {
    return user?.isBusinessUser && user.accountOffChainPermissionsExtended?.includes(EAccountOffChainPermission.ACC_OFF_CHAIN_ADMIN)
  }
}
