import { graphql } from 'relay-runtime'

import { AddAddressToExclusionListMutation } from './__generated__/AddAddressToExclusionListMutation.graphql'

export type { AddAddressToExclusionListMutation }

export const addAddressToExclusionListMutation = graphql`
  mutation AddAddressToExclusionListMutation($input: AddAddressToExclusionListInput!) {
    addAddressToExclusionList(input: $input) {
      code
      message
    }
  }
`
