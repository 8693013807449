import { FC, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'
import { EMPTY, catchError, from, switchMap, takeUntil } from 'rxjs'
import { AuthApi } from 'src/api'
import { Loading } from 'src/atoms'
import { AUTH_FALLBACK_KEY } from 'src/constants'
import { useQueryParams, useUnsubscribe } from 'src/hooks'
import { ERoutes, generate } from 'src/router'
import { AuthService, MessageService } from 'src/services'
import { StorageUtils } from 'src/utils'

export const OAuth2Callback: FC = () => {
  const navigate = useNavigate()
  const unsubscribe$ = useUnsubscribe()
  const { provider } = useParams<{ provider: string }>()
  const params = useQueryParams()

  useEffect(() => {
    from(AuthApi.callbackOAuth(provider as Parameters<typeof AuthApi.callbackOAuth>[0], params))
      .pipe(
        takeUntil(unsubscribe$),
        switchMap(() => from(AuthService.getProfile())),
        catchError((error) => {
          MessageService.axiosError(error)
          navigate(ERoutes.LOGIN)
          return EMPTY
        })
      )
      .subscribe(() => {
        const toURL = StorageUtils.getItem<string>(AUTH_FALLBACK_KEY)
        if (toURL) {
          StorageUtils.removeItem(AUTH_FALLBACK_KEY)
          return navigate(toURL)
        }
        navigate(generate(ERoutes.ADMIN_MANAGEMENT))
      })
  }, [navigate, params, provider, unsubscribe$])

  return (
    <div className="fx fx-column">
      <Loading show/>
    </div>
  )
}
