import { Collapse, CollapseProps, Descriptions, DescriptionsProps, Table } from 'antd'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { EMPTY, catchError, from, takeUntil } from 'rxjs'
import { EcoRewardApi, IPointDistributionData, KolTrackerApi, ReferralApi } from 'src/api'
import { TAccountKolRewards, TAccountRaffleInfo } from 'src/graphql'
import { useUnsubscribe } from 'src/hooks'
import { DialogAddEcosystemPoints, DialogAddRaffleTickets, DialogAmountPaid } from 'src/partials'
import { EPaths } from 'src/router'
import { BreadcrumbService, MessageService, TzService } from 'src/services'
import { NumberUtils } from 'src/utils'

export const KOLRewardDetails: FC = () => {
  const unsubscribe$ = useUnsubscribe()
  const { accountID } = useParams<{ accountID: string }>()

  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = [
      {
        route: EPaths.BD_KOL_TRACKER,
        label: 'KOL Tracker'
      },
      {
        route: [EPaths.BD_KOL_REWARD_DETAILS, { accountID }],
        label: accountID
      }
    ]
    return () => {
      BreadcrumbService.items = []
    }
  }, [accountID])

  const [accountRaffleInfo, setAccountRaffleInfo] = useState<TAccountRaffleInfo | null>(null)
  const [pointDistributions, setPointDistributions] = useState<IPointDistributionData[]>([])
  const [accountKolRewards, setAccountKolRewards] = useState<TAccountKolRewards[]>([])

  const currentRaffleTickets = useMemo(() => {
    return Math.max((accountRaffleInfo?.totalTickets || 0) - (accountRaffleInfo?.totalUsedTickets || 0), 0)
  }, [accountRaffleInfo])

  const awardedPoints = useMemo(() => {
    return pointDistributions.find((item) => item.account_id === accountID)?.total_point || 0
  }, [accountID, pointDistributions])

  const fetchAccountRaffleInfo = useCallback(() => {
    if (!accountID) return
    from(ReferralApi.accountRaffleInfo({ accountID }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          MessageService.error(error)
          return EMPTY
        })
      )
      .subscribe((res) => {
        setAccountRaffleInfo(res)
      })
  }, [unsubscribe$, accountID])

  const fetchListPointDistribution = useCallback(() => {
    from(EcoRewardApi.getListPointDistributions())
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          MessageService.error(error)
          return EMPTY
        })
      )
      .subscribe((res) => {
        setPointDistributions(Array.ensure(res.data.point_distributions))
      })
  }, [unsubscribe$])

  const fetchAccountKolRewards = useCallback(() => {
    if (!accountID) return
    from(KolTrackerApi.accountKolRewards({ where: { accountID } }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          MessageService.error(error)
          return EMPTY
        })
      )
      .subscribe((res) => {
        res && setAccountKolRewards(res as TAccountKolRewards[])
      })
  }, [unsubscribe$, accountID])

  useEffect(() => {
    fetchAccountRaffleInfo()
    fetchListPointDistribution()
    fetchAccountKolRewards()
  }, [fetchAccountRaffleInfo, fetchListPointDistribution, fetchAccountKolRewards])

  const rewardDesc: DescriptionsProps['items'] = useMemo(
    () => [
      {
        label: 'Current Raffle Tickets',
        children: (
          <div>
            {currentRaffleTickets}
            <DialogAddRaffleTickets
              accountID={accountID}
              btnProps={{ size: 'small', type: 'link' }}
              afterClose={(fresh) => fresh && fetchAccountRaffleInfo()}
            >
              Add
            </DialogAddRaffleTickets>
          </div>
        )
      },
      {
        label: 'Awarded Points',
        children: (
          <div>
            {awardedPoints}
            <DialogAddEcosystemPoints
              accountID={accountID}
              btnProps={{ size: 'small', type: 'link' }}
              afterClose={(fresh) => fresh && fetchListPointDistribution()}
            >
              Add
            </DialogAddEcosystemPoints>
          </div>
        )
      }
    ].map((el, index) => ({
      ...el,
      key: index,
      style: { width: '50%' }
    })),
    [
      accountID,
      currentRaffleTickets,
      awardedPoints,
      fetchAccountRaffleInfo,
      fetchListPointDistribution
    ]
  )

  const items: CollapseProps['items'] = useMemo(() => [
    {
      key: '1',
      label: 'KOL Reward Details',
      children: (
        <Descriptions
          layout="vertical"
          size="small"
          column={2}
          bordered
          items={rewardDesc}
        />
      )
    }
  ], [rewardDesc])

  return (
    <section className="fx fx-column fx-extend gap-4">
      <Collapse
        items={items}
        defaultActiveKey={['1']}
      />
      <Table
        bordered
        rowKey="id"
        className="fx-extend"
        dataSource={accountKolRewards}
        columns={[
          {
            title: 'Epoch',
            dataIndex: 'epoch',
            key: 'epoch',
            width: 140,
            render: (epoch) => epoch.epoch
          },
          {
            title: 'Epoch Earnings (USD)',
            dataIndex: 'earningAmount',
            key: 'earningAmount',
            render: (val: TAccountKolRewards['earningAmount']) => NumberUtils.formatNumber(val ? val / 1e6 : 0, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })
          },
          {
            title: 'Amount Due',
            dataIndex: 'dueAmount',
            key: 'dueAmount',
            render: (val: TAccountKolRewards['dueAmount']) => NumberUtils.formatNumber(val ? val / 1e6 : 0, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })
          },
          {
            title: 'Amount Paid',
            dataIndex: 'paidAmount',
            key: 'paidAmount',
            width: 140,
            render: (val: TAccountKolRewards['paidAmount'], record: TAccountKolRewards) => (
              <div className="fx fx-ai-center gap-2">
                <DialogAmountPaid
                  accountKolReward={record}
                  afterClose={(fresh) => fresh && fetchAccountKolRewards()}
                >
                  {val}
                </DialogAmountPaid>
              </div>
            )
          },
          {
            title: 'Last Updated',
            dataIndex: 'updateTime',
            key: 'updateTime',
            render: (value: TAccountKolRewards['updateTime']) => value
              ? TzService.format(value, 'HH:mm YYYY-MM-DD')
              : '--',
            width: 140
          },
          {
            title: 'Comment',
            dataIndex: 'comment',
            key: 'comment',
            width: 140
          }
        ]}
        tableLayout="fixed"
        scroll={{ x: true }}
      />
    </section>
  )
}
