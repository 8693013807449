import { Button, Input, Select, Table } from 'antd'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Pagination } from 'src/atoms'
import { KolRewardsQuery, kolRewardsQuery, TKolReward } from 'src/graphql'
import { useBehaviorMapper, useDidMountDebounce } from 'src/hooks'
import { EPaths } from 'src/router'
import { BreadcrumbService, PaginationService } from 'src/services'
import { KOLEpochsStore } from 'src/store'
import { renderColumns } from './columns'

export const InstitutionTracker: FC = () => {
  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = [{
      route: EPaths.BD_KOL_TRACKER,
      label: 'Inst. Tracker'
    }]
    return () => {
      BreadcrumbService.items = []
    }
  }, [])

  const kolEpochsOptions = useBehaviorMapper(KOLEpochsStore.kolEpochsOptions$)
  useEffect(() => {
    KOLEpochsStore.fetchKolEpochs()
  }, [])

  const _paginationService = useMemo(() => new PaginationService<TKolReward>(kolRewardsQuery), [])
  const loading = useBehaviorMapper(_paginationService.loading$)
  const dataSource = useBehaviorMapper(_paginationService.items$)

  const [keyword, setKeyword] = useState('')
  const [filter, setFilter] = useState<{
    query?: string
    epochID?: string
  }>({})

  const computedWhere = useMemo<KolRewardsQuery['variables']['where']>(() => {
    const conditions: KolRewardsQuery['variables']['where'] = {
      hasAccountWith: [{ isBusiness: true }]
    }
    if (filter.epochID) {
      conditions.hasEpochWith = [{ id: filter.epochID }]
    }
    if (filter.query) {
      conditions.hasAccountWith = [
        ...(conditions.hasAccountWith ?? []),
        {
          or: [
            { nameContainsFold: filter.query.trim() },
            { displayNameContainsFold: filter.query.trim() }
          ]
        }
      ]
    }
    return conditions
  }, [filter])

  const fresh = useCallback((params?: Parameters<typeof _paginationService.fetch>[0]) => {
    if (!filter.epochID) {
      return
    }
    _paginationService.fetch(params)
  }, [_paginationService, filter.epochID])

  useDidMountDebounce(() => {
    fresh({
      after: '',
      before: '',
      where: computedWhere
    })
  }, 500, [computedWhere, fresh])

  useEffect(() => {
    fresh({
      after: '',
      before: '',
      where: computedWhere
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!filter.epochID && kolEpochsOptions?.[0]?.value) {
      setFilter((prev) => ({
        ...prev,
        epochID: kolEpochsOptions?.[0]?.value
      }))
    }
  }, [filter.epochID, kolEpochsOptions])

  return (
    <section className="fx fx-column fx-extend gap-2">
      <div className="fx fx-jc-space-between">
        <div className="fx fx-extend fx-ai-center gap-2 label-1">
          Inst. Tracker
          <Select
            allowClear
            style={{ flex: '0 0 100px' }}
            placeholder="Epoch #"
            value={filter.epochID}
            options={kolEpochsOptions}
            onChange={(value) => setFilter((prev) => ({
              ...prev,
              epochID: value
            }))}
          />
        </div>

        <div className="fx fx-ai-center fx-jc-end gap-2">
          <Input.Search
            allowClear
            placeholder="Search..."
            style={{ flex: '0 0 200px' }}
            readOnly={loading}
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            onSearch={() => setFilter((prev) => ({
              ...prev,
              query: keyword
            }))}
          />
          {/* <Button type="primary" disabled>
            Clear
          </Button> */}
          <Link to={EPaths.BD_INST_CONFIGURATIONS_HISTORY}>
            <Button type="primary">
              See history
            </Button>
          </Link>
        </div>
      </div>

      <Table
        bordered
        rowKey="id"
        className="fx-extend"
        loading={loading}
        dataSource={dataSource}
        pagination={false}
        columns={renderColumns({ onFresh: fresh })}
        tableLayout="fixed"
        scroll={{ x: true }}
      />

      <Pagination
        className="fx-as-end mt-3"
        disabled={loading}
        canPrev={_paginationService.canPrev}
        canNext={_paginationService.canNext}
        onPrev={() => _paginationService.prev({ where: computedWhere })}
        onNext={() => _paginationService.next({ where: computedWhere })}
      />
    </section>
  )
}
