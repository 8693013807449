import { FC, useEffect } from 'react'
import { EPaths } from 'src/router'
import { BreadcrumbService } from 'src/services'

export const Settings: FC = () => {
  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = [{
      route: EPaths.SETTING,
      label: 'Settings'
    }]
    return () => {
      BreadcrumbService.items = []
    }
  }, [])

  return (
    <section>
      <b>Feature under development</b>
    </section>
  )
}
