import { graphql } from 'relay-runtime'

import { BdPerformanceQuery } from './__generated__/BdPerformanceQuery.graphql'

export type { BdPerformanceQuery }

export type TBdPerformance = TArrayItem<Required<Partial<DeepWriteable<BdPerformanceQuery>['response']['bdPerformance']['data']>>>

export const bdPerformanceQuery = graphql`
  query BdPerformanceQuery($input: BDPerformanceInput!) {
    bdPerformance(input: $input) {
      total
      data {
        ...on BDPerformance {
          id
          email
          clientType
          commissionRate
          numberOfClientsInCharge
          clientEarnings
          bdEarnings
        }
      }
    }
  }
`
