import { AdminApi } from 'src/api'
import { TAdminPortalUser } from 'src/graphql'

export const AdminStore = new (
  class {
    fetchBdList() {
      return AdminApi.adminList({}).then(
        (res) => Array.ensure(res.edges)
          .map((edge) => edge?.node as TAdminPortalUser)
          .filter((admin) => admin?.adminPortalUserPermissionsExtended?.includes?.('ADMIN_PORTAL_MARKETING'))
      )
    }
  }
)()
