import { Button, DatePicker, Form, Input, Modal, Select } from 'antd'
import dayjs from 'dayjs'
import { ComponentProps, FC, useCallback, useEffect, useState } from 'react'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { PiiApi } from 'src/api'
import { TAdminInvitee, TUserPii } from 'src/graphql'
import { useAsRef, useBehaviorMapper, useUnsubscribe } from 'src/hooks'
import { countryDocumentTypeOptions } from 'src/interfaces'
import { MessageService } from 'src/services'
import { CountriesStore } from 'src/store'
import { StringUtils } from 'src/utils'

type FieldType = {
  firstNameAndMiddleName?: string
  lastName?: string
  dateOfBirth?: dayjs.Dayjs
  nationality?: string
  countryOfResidence?: string
  documentNumber?: string
  documentType?: string
  issueCountry?: string
}

export const DialogEditKycIdentity: FC<Omit<
  ComponentProps<typeof Modal>,
  'onOk' | 'afterClose'
> & {
  user: TUserPii
  btnProps?: ComponentProps<typeof Button>
  afterClose?: (saved?: boolean) => any
}> = ({
  user,
  btnProps,
  ...props
}) => {
  const unsubscribe$ = useUnsubscribe()
  const [loading, setLoading] = useState(false)
  const [invisible, setInvisible] = useState<boolean | TAdminInvitee>(true)

  const countriesOptions = useBehaviorMapper(CountriesStore.countriesOptions$)

  useEffect(() => {
    setInvisible(!props.open)
  }, [props.open])

  const afterCloseRef = useAsRef(props.afterClose)

  const [form] = Form.useForm<FieldType>()
  useEffect(() => {
    if (!invisible) {
      form.setFieldsValue({
        firstNameAndMiddleName: user?.firstName || '',
        lastName: user?.lastName || '',
        dateOfBirth: user?.dateOfBirth ? dayjs(new Date(String(user?.dateOfBirth)?.split('T')[0])) : undefined,
        nationality: user?.nationality || '',
        countryOfResidence: user?.currentKyc?.countryOfResidence || '',
        documentNumber: user?.documentNumber || '',
        documentType: user?.documentType || '',
        issueCountry: user?.documentIssueCountry || ''
      })
    }
  }, [invisible, user, form])

  const submit = useCallback((values: FieldType) => {
    setLoading(true)
    from(PiiApi.adminUpdateUserIdentity({
      input: {
        firstName: values.firstNameAndMiddleName!,
        lastName: values.lastName!,
        dateOfBirth: values.dateOfBirth?.format('YYYY-MM-DD') || '',
        nationality: values.nationality!,
        countryOfResidence: values.countryOfResidence,
        userID: user?.userID || '',
        documentNumber: values?.documentNumber || '',
        documentType: values?.documentType || '',
        issueCountry: values?.issueCountry || ''
      }
    }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          MessageService.error(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(() => {
        MessageService.info('Edit User Identity successfully')
        setInvisible(true)
        afterCloseRef.current?.(true)
      })
  }, [unsubscribe$, afterCloseRef, user?.userID])

  return (
    <>
      <Button
        type="primary"
        {...btnProps}
        onClick={() => setInvisible(false)}
      >
        {props.children || 'Edit'}
      </Button>

      <Modal
        title={props.title || 'Edit User Identity'}
        open={!invisible}
        centered
        closable={!loading} // display X icon
        keyboard={false} // disable close on press ESC
        maskClosable={false} // disable close on click outside
        okButtonProps={{ disabled: loading }}
        cancelButtonProps={{ disabled: loading }}
        okText="Ok"
        cancelText="Cancel"
        onOk={() => form.submit()}
        onCancel={() => setInvisible(true)}
      >
        {!invisible && (
          <Form
            form={form}
            name="edit-kyc-identity"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            onFinish={submit}
            autoComplete="off"
          >
            <Form.Item<FieldType>
              label="First & Middle Name"
              name="firstNameAndMiddleName"
              rules={[{ required: true }]}
            >
              <Input/>
            </Form.Item>

            <Form.Item<FieldType>
              label="Last Name"
              name="lastName"
              rules={[{ required: true }]}
            >
              <Input/>
            </Form.Item>

            <Form.Item
              label="Date of birth"
              name="dateOfBirth"
              rules={[{ required: true }]}
            >
              <DatePicker value={form.getFieldValue('')} placeholder="" style={{ width: '100%' }}/>
            </Form.Item>

            <Form.Item<FieldType>
              label="Nationality"
              name="nationality"
              rules={[{ required: true }]}
            >
              <Select
                allowClear
                showSearch
                options={countriesOptions}
                filterOption={(input, option) => (
                  StringUtils.containsWithoutDiacritics(option?.label, input) ||
                  StringUtils.containsWithoutDiacritics(option?.value, input)
                )}
              />
            </Form.Item>

            <Form.Item<FieldType>
              label="Country of residence"
              name="countryOfResidence"
              rules={[{ required: true }]}
            >
              <Select
                allowClear
                showSearch
                options={countriesOptions}
                filterOption={(input, option) => (
                  StringUtils.containsWithoutDiacritics(option?.label, input) ||
                  StringUtils.containsWithoutDiacritics(option?.value, input)
                )}
              />
            </Form.Item>

            <Form.Item<FieldType>
              label="Document number"
              name="documentNumber"
              rules={[{ required: true }]}
            >
              <Input/>
            </Form.Item>

            <Form.Item<FieldType>
              label="Document type"
              name="documentType"
              rules={[{ required: true }]}
            >
              <Select
                allowClear
                showSearch
                options={countryDocumentTypeOptions}
                filterOption={(input, option) => (
                  StringUtils.containsWithoutDiacritics(option?.label, input) ||
                  StringUtils.containsWithoutDiacritics(option?.value, input)
                )}
              />
            </Form.Item>

            <Form.Item<FieldType>
              label="Issue country"
              name="issueCountry"
              rules={[{ required: true }]}
            >
              <Select
                allowClear
                showSearch
                options={countriesOptions}
                filterOption={(input, option) => (
                  StringUtils.containsWithoutDiacritics(option?.label, input) ||
                  StringUtils.containsWithoutDiacritics(option?.value, input)
                )}
              />
            </Form.Item>
          </Form>
        )}
      </Modal>
    </>
  )
}
