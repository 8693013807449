import { graphql } from 'relay-runtime'

import { AddKYCAttemptCommentMutation } from './__generated__/AddKYCAttemptCommentMutation.graphql'

export type { AddKYCAttemptCommentMutation }

export const addKYCAttemptCommentMutation = graphql`
  mutation AddKYCAttemptCommentMutation($input: KYCAttemptCommentInput!) {
    addKYCAttemptComment(input: $input) {
      ...on KYCActionLog {
        id
        # createTime
        # updateTime
        # kycAttemptID
        # subjectUserID
        # subjectType
        # targetUserID
        # action
        # prevAttemptState
        # currentAttemptState
        # prevReviewState
        # currentReviewState
        # message
        # data
      }
    }
  }
`
