import { graphql } from 'relay-runtime'

import { KolStatisticsQuery } from './__generated__/KolStatisticsQuery.graphql'

export type { KolStatisticsQuery }

export type TKolStatisticsStatus = Partial<DeepWriteable<KolStatisticsQuery>['response']['kolStatistics']>
export type TKolStatistics = Partial<TArrayItem<TKolStatisticsStatus['data']>>

export const kolStatisticsQuery = graphql`
  query KolStatisticsQuery($input: KOLStatisticsInput!) {
    kolStatistics(input: $input) {
      total
      data {
        ...on KOLStatistic {
          accountID
          email
          name
          joinedDate
          tier
          totalDirectReferrals
          totalIndirectReferrals
          totalDirectPhase0Referrals
          totalIndirectPhase0Referrals
          totalDirectPhase1Referrals
          totalIndirectPhase1Referrals
          totalPoints
          totalPhase0Points
          totalPhase1Points
          rank
          projectedTotalCodes
          projectedEfficiencyRate
          # kycStatistics {
          #   ...on KYCStatistic {
          #     state
          #     total
          #   }
          # }
          tradingVolume
          directInviteTradingVolume
          indirectInviteTradingVolume
          directInviteTradingFee
          indirectInviteTradingFee
          directCommissionRate
          indirectCommissionRate
          commissionTier
          epochEarning
          bdInCharge {
            ...on AdminPortalUser {
              id
              email
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`
