import { EPaths, IRouterOptions } from 'src/router'
import { MarketingArticles, MarketingShortLinks } from './components'

export enum ERoutes {
  MARKETING_ARTICLE = 'MARKETING_ARTICLE',
  MARKETING_SHORT_LINK = 'MARKETING_SHORT_LINK'
}

export const routes: IRouterOptions = [
  {
    path: EPaths.MARKETING_ARTICLE,
    name: ERoutes.MARKETING_ARTICLE,
    component: MarketingArticles,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: EPaths.MARKETING_SHORT_LINK,
    name: ERoutes.MARKETING_SHORT_LINK,
    component: MarketingShortLinks,
    meta: {
      requiredAuth: true
    }
  }
]

export const MarketingModule = {
  ERoutes,
  routes
}
