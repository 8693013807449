import { graphql } from 'relay-runtime'

import { UpdatePersonalInformationMutation } from './__generated__/UpdatePersonalInformationMutation.graphql'

export type { UpdatePersonalInformationMutation }

export const updatePersonalInformationMutation = graphql`
  mutation UpdatePersonalInformationMutation($input: UpdateUserIdentityInput!) {
    updatePersonalInformation(input: $input) {
      data {
        ...on KYCAttempt {
          id
          # createTime
          # updateTime
          # userIdentityID
          # state
          # countryOfResidence
          # userType
          # extractedInfoID
          # extractedInfo {
          #   ...on KYCExtractedInfo {
          #     id
          #     createTime
          #     ccCheckID
          #     kycAttemptID
          #     firstName
          #     lastName
          #     dateOfBirth
          #     nationality
          #     documentNumber
          #     documentType
          #   }
          # }
        }
      }
    }
  }
`
