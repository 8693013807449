import { graphql } from 'relay-runtime'

import { UpdateBDConfigMutation } from './__generated__/UpdateBDConfigMutation.graphql'

export type { UpdateBDConfigMutation }

export const updateBDConfigMutation = graphql`
  mutation UpdateBDConfigMutation($input: UpdateBDConfigInput!) {
    updateBDConfig(input: $input) {
      code
      message
    }
  }
`
