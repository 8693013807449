export enum EBusinessType {
  LTD = 'LTD',
  LLC = 'LLC',
  INC = 'INC',
  CORP = 'CORP',
  PLC = 'PLC',
  LP = 'LP',
  LLP = 'LLP',
  SP= 'SP',
  OTHER= 'OTHER'
}

export const EBusinessTypeLabel = {
  [EBusinessType.LTD]: 'Limited',
  [EBusinessType.LLC]: 'Limited Liability Company',
  [EBusinessType.INC]: 'Incorporated',
  [EBusinessType.CORP]: 'Corporation',
  [EBusinessType.PLC]: 'Public Limited Company',
  [EBusinessType.LP]: 'Limited Partnership',
  [EBusinessType.LLP]: 'Limited Liability Partnership',
  [EBusinessType.SP]: 'Sole Proprietorship',
  [EBusinessType.OTHER]: 'Other'
}

export const EBusinessTypeOptions = Object.keys(EBusinessType).map((key) => ({
  value: key,
  label: EBusinessTypeLabel[key as EBusinessType]
}))

export enum KYBDocumentType {
  CERT_INCORPORATION = 'CERT_INCORPORATION',
  CERT_INCUMBENCY = 'CERT_INCUMBENCY',
  MEMORANDUM_ARTICLES = 'MEMORANDUM_ARTICLES',
  BOARD_RESOLUTION = 'BOARD_RESOLUTION',
  CORPORATE_STRUCTURE_OWNERSHIP = 'CORPORATE_STRUCTURE_OWNERSHIP',
  REGISTER_OF_DIRECTORS = 'REGISTER_OF_DIRECTORS',
  REGISTER_OF_SHAREHOLDERS = 'REGISTER_OF_SHAREHOLDERS',
  BENEFICIAL_OWNER_DECLARATION = 'BENEFICIAL_OWNER_DECLARATION',
  PROOF_SOURCE_FUNDS = 'PROOF_SOURCE_FUNDS',
  BUSINESS_DETAILS = 'BUSINESS_DETAILS',
  STAKEHOLDER_DETAILS = 'STAKEHOLDER_DETAILS',
  OTHER = 'OTHER'
}

export const KYBDocumentTypeLabel = {
  [KYBDocumentType.CERT_INCORPORATION]: 'Certificate of incorporation',
  [KYBDocumentType.CERT_INCUMBENCY]: 'Certificate of incumbency',
  [KYBDocumentType.MEMORANDUM_ARTICLES]: 'Memorandum and Articles of Association',
  [KYBDocumentType.BOARD_RESOLUTION]: 'Board resolution',
  [KYBDocumentType.CORPORATE_STRUCTURE_OWNERSHIP]: "Corporate Structure Ownership Chart with Date & Directors' Signature",
  [KYBDocumentType.REGISTER_OF_DIRECTORS]: 'Register of Directors',
  [KYBDocumentType.REGISTER_OF_SHAREHOLDERS]: 'Register of Shareholders/Members',
  [KYBDocumentType.BENEFICIAL_OWNER_DECLARATION]: 'Beneficial Owner Declaration',
  [KYBDocumentType.PROOF_SOURCE_FUNDS]: 'Proof of Source of Funds',
  [KYBDocumentType.BUSINESS_DETAILS]: 'Business details',
  [KYBDocumentType.STAKEHOLDER_DETAILS]: 'Stakeholder details',
  [KYBDocumentType.OTHER]: 'Other'
}

export enum EKybStatus {
  NOT_STARTED = 'NOT_STARTED',
  ONGOING = 'ONGOING',
  REVIEW_IN_PROGRESS = 'REVIEW_IN_PROGRESS',
  DONE = 'DONE',
  REJECTED = 'REJECTED',
  ON_HOLD = 'ON_HOLD'
}

export const EKybStatusTxt = Object.freeze({
  [EKybStatus.NOT_STARTED]: 'Not started',
  [EKybStatus.ONGOING]: 'Ongoing',
  [EKybStatus.REVIEW_IN_PROGRESS]: 'Review in progress',
  [EKybStatus.DONE]: 'Done',
  [EKybStatus.REJECTED]: 'Rejected',
  [EKybStatus.ON_HOLD]: 'On hold'
})

export const EKybStatusBadge = Object.freeze({
  [EKybStatus.NOT_STARTED]: 'default',
  [EKybStatus.ONGOING]: 'processing',
  [EKybStatus.REVIEW_IN_PROGRESS]: 'processing',
  [EKybStatus.DONE]: 'success',
  [EKybStatus.REJECTED]: 'error',
  [EKybStatus.ON_HOLD]: 'warning'
})

export const EKybStatusOptions = Object.values(EKybStatus).map((key) => ({
  value: key,
  label: EKybStatusTxt[key]
}))
