import { EPaths, IRouterOptions } from 'src/router'
import { BDTrackerKolTracker } from './components'
import { BDPerformance } from './components/bd-performance'
import { BDCommissionRateHistory } from './components/bd-performance/bd-commission-rate-history'
import { InstitutionTracker } from './components/institution-tracker'
import { InstConfigurationsHistory } from './components/institution-tracker/configurations-history'
import { InstPaymentDetails } from './components/institution-tracker/payment-details'
import { KOLConfigurationsHistory } from './components/kol-tracker/configurations-history'
import { KOLPointDistribution } from './components/kol-tracker/point-distribution'
import { KOLRewardDetails } from './components/kol-tracker/reward-details'

export enum ERoutes {
  BD_KOL_TRACKER = 'BD_KOL_TRACKER',
  BD_KOL_REWARD_DETAILS = 'BD_KOL_REWARD_DETAILS',
  BD_KOL_CONFIGURATIONS_HISTORY = 'BD_KOL_CONFIGURATIONS_HISTORY',
  BD_KOL_POINT_DISTRIBUTION = 'BD_KOL_POINT_DISTRIBUTION',
  BD_INSTITUTION_TRACKER = 'BD_INSTITUTION_TRACKER',
  BD_INST_CONFIGURATIONS_HISTORY = 'BD_INST_CONFIGURATIONS_HISTORY',
  BD_INST_PAYMENT_DETAILS = 'BD_INST_PAYMENT_DETAILS',
  BD_PERFORMANCE = 'BD_PERFORMANCE',
  BD_COMMISSION_RATE_HISTORY = 'BD_COMMISSION_RATE_HISTORY'
}

export const routes: IRouterOptions = [
  {
    path: EPaths.BD_KOL_TRACKER,
    name: ERoutes.BD_KOL_TRACKER,
    component: BDTrackerKolTracker,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: EPaths.BD_KOL_REWARD_DETAILS,
    name: ERoutes.BD_KOL_REWARD_DETAILS,
    component: KOLRewardDetails,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: EPaths.BD_KOL_CONFIGURATIONS_HISTORY,
    name: ERoutes.BD_KOL_CONFIGURATIONS_HISTORY,
    component: KOLConfigurationsHistory,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: EPaths.BD_KOL_POINT_DISTRIBUTION,
    name: ERoutes.BD_KOL_POINT_DISTRIBUTION,
    component: KOLPointDistribution,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: EPaths.BD_INSTITUTION_TRACKER,
    name: ERoutes.BD_INSTITUTION_TRACKER,
    component: InstitutionTracker,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: EPaths.BD_INST_CONFIGURATIONS_HISTORY,
    name: ERoutes.BD_INST_CONFIGURATIONS_HISTORY,
    component: InstConfigurationsHistory,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: EPaths.BD_INST_PAYMENT_DETAILS,
    name: ERoutes.BD_INST_PAYMENT_DETAILS,
    component: InstPaymentDetails,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: EPaths.BD_PERFORMANCE,
    name: ERoutes.BD_PERFORMANCE,
    component: BDPerformance,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: EPaths.BD_COMMISSION_RATE_HISTORY,
    name: ERoutes.BD_COMMISSION_RATE_HISTORY,
    component: BDCommissionRateHistory,
    meta: {
      requiredAuth: true
    }
  }
]

export const BusinessDevelopmentModule = {
  ERoutes,
  routes
}
