import { graphql } from 'relay-runtime'

import { StakeholderIdentitiesQuery } from './__generated__/StakeholderIdentitiesQuery.graphql'

export type { StakeholderIdentitiesQuery }

export type TStakeholderIdentities = DeepWriteable<NonNullable<TArrayItem<Partial<StakeholderIdentitiesQuery['response']['stakeholderIdentities']['stakeholders']>>>>

export const stakeholderIdentitiesQuery = graphql`
  query StakeholderIdentitiesQuery($accountID: ID!) {
    stakeholderIdentities(accountID: $accountID) {
      code
      message
      stakeholders {
        accountID
        stakeholderID
        role
        entity {
          ...on StakeholderIdentityEntity {
            companyName
            countryOfIncorporation
            companyRegistrationNumber
            dateOfIncorporation
            registeredOfficeAddress
          }
        }
        individual {
          ...on StakeholderIdentityIndividual {
            firstName
            lastName
            fullAddress
            dateOfBirth
            countryOfResidence
            nationality
            countryOfBirth
          }
        }
        deletedAt
      }
    }
  }
`
