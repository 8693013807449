import { graphql } from 'relay-runtime'

import { AdminPortalUsersQuery } from './__generated__/AdminPortalUsersQuery.graphql'

export type { AdminPortalUsersQuery }

export type TAdminPortalUser = DeepWriteable<DeepExclude<TArrayItem<AdminPortalUsersQuery['response']['adminPortalUsers']['edges']>, null>['node']>

export const adminPortalUsersQuery = graphql`
  query AdminPortalUsersQuery(
    $after: Cursor
    $first: Int
    $before: Cursor
    $last: Int
    $where: AdminPortalUserWhereInput
  ) {
    adminPortalUsers(
      after: $after
      first: $first
      before: $before
      last: $last
      where: $where
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...on AdminPortalUser {
            id
            createTime
            updateTime
            firstName
            lastName
            email
            confirmed
            attemptCount
            lastAttempt
            locked
            oAuth2UID
            oAuth2Provider
            adminPortalUserPermissionsExtended
          }
        }
      }
    }
  }
`
