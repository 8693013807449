export enum EKycStatus {
  APPLICATION_NOT_STARTED = 'APPLICATION_NOT_STARTED',
  APPLICATION_ONGOING = 'APPLICATION_ONGOING',
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  INFO_REQUESTED = 'INFO_REQUESTED',
  ESCALATED = 'ESCALATED',
  TERMINAL_BLACKLISTED = 'TERMINAL_BLACKLISTED',
  RESTART_IDENTITY_CHECK_FAILED = 'RESTART_IDENTITY_CHECK_FAILED',
  RESTART_CLOSED = 'RESTART_CLOSED',
  TERMINAL_IDENTITY_BLACKLISTED = 'TERMINAL_IDENTITY_BLACKLISTED',
  FAILURE_AUTO_TERMINAL_REPEATED = 'FAILURE_AUTO_TERMINAL_REPEATED',
  RESTART_REJECTED = 'RESTART_REJECTED',
  SUCCESS_MANUAL = 'SUCCESS_MANUAL',
  SUCCESS_AUTO = 'SUCCESS_AUTO',
  ON_HOLD = 'ON_HOLD'
}

export const EKycStatusTxt = Object.freeze({
  [EKycStatus.APPLICATION_NOT_STARTED]: 'Application not started',
  [EKycStatus.APPLICATION_ONGOING]: 'Application ongoing',
  [EKycStatus.PENDING]: 'Pending',
  [EKycStatus.IN_PROGRESS]: 'In progress',
  [EKycStatus.INFO_REQUESTED]: 'Info requested',
  [EKycStatus.ESCALATED]: 'Escalated',
  [EKycStatus.TERMINAL_BLACKLISTED]: 'Terminal blacklisted',
  [EKycStatus.RESTART_IDENTITY_CHECK_FAILED]: 'Restart identity check failed',
  [EKycStatus.RESTART_CLOSED]: 'Restart closed',
  [EKycStatus.TERMINAL_IDENTITY_BLACKLISTED]: 'Terminal identity blacklisted',
  [EKycStatus.FAILURE_AUTO_TERMINAL_REPEATED]: 'Failure auto terminal repeated',
  [EKycStatus.RESTART_REJECTED]: 'Restart rejected',
  [EKycStatus.SUCCESS_MANUAL]: 'Success manual',
  [EKycStatus.SUCCESS_AUTO]: 'Success auto',
  [EKycStatus.ON_HOLD]: 'On hold'
})

export const EKycStatusOptions = Object.values(EKycStatus).map((key) => ({
  value: key,
  label: EKycStatusTxt[key]
}))

export const EKycStatusBadge = Object.freeze({
  [EKycStatus.APPLICATION_NOT_STARTED]: 'default',
  [EKycStatus.APPLICATION_ONGOING]: 'default',
  [EKycStatus.ESCALATED]: 'default',
  [EKycStatus.INFO_REQUESTED]: 'default',
  [EKycStatus.ON_HOLD]: 'processing',
  [EKycStatus.IN_PROGRESS]: 'processing',
  [EKycStatus.PENDING]: 'processing',
  [EKycStatus.RESTART_CLOSED]: 'warning',
  [EKycStatus.RESTART_IDENTITY_CHECK_FAILED]: 'warning',
  [EKycStatus.RESTART_REJECTED]: 'warning',
  [EKycStatus.FAILURE_AUTO_TERMINAL_REPEATED]: 'warning',
  [EKycStatus.TERMINAL_BLACKLISTED]: 'error',
  [EKycStatus.TERMINAL_IDENTITY_BLACKLISTED]: 'error',
  [EKycStatus.SUCCESS_AUTO]: 'success',
  [EKycStatus.SUCCESS_MANUAL]: 'success'
})

export enum EKycTag {
  ID_ATTENTION = 'ID_ATTENTION',
  DOCUMENT_ATTENTION = 'DOCUMENT_ATTENTION',
  ID_MISMATCH = 'ID_MISMATCH',
  ID_EDITED = 'ID_EDITED',
  POA_MISMATCH = 'POA_MISMATCH',
  POA_EXPIRED = 'POA_EXPIRED',
  POA_ATTENTION = 'POA_ATTENTION',
  AML_HIT = 'AML_HIT',
  HIGH_RISK = 'HIGH_RISK',
  MEDIUM_RISK = 'MEDIUM_RISK',
  LOW_RISK = 'LOW_RISK',
  IP_COUNTRY_MISMATCH = 'IP_COUNTRY_MISMATCH',
  PROHIBITED_COUNTRY = 'PROHIBITED_COUNTRY',
  UNSUPPORTED_DOCUMENT_TYPE = 'UNSUPPORTED_DOCUMENT_TYPE',
  ISSUING_COUNTRY_MISSING = 'ISSUING_COUNTRY_MISSING',
  IDENTITY_CHECK_NOT_PROCESSED = 'IDENTITY_CHECK_NOT_PROCESSED',
  DOCUMENT_CHECK_NOT_PROCESSED = 'DOCUMENT_CHECK_NOT_PROCESSED'
}

export const EKycTagTxt = Object.freeze({
  [EKycTag.ID_ATTENTION]: 'ID Attention',
  [EKycTag.DOCUMENT_ATTENTION]: 'Document Attention',
  [EKycTag.ID_MISMATCH]: 'ID Mismatch',
  [EKycTag.ID_EDITED]: 'ID Edited',
  [EKycTag.POA_MISMATCH]: 'POA Mismatch',
  [EKycTag.POA_EXPIRED]: 'POA Expired',
  [EKycTag.POA_ATTENTION]: 'POA Attention',
  [EKycTag.AML_HIT]: 'AML Hit',
  [EKycTag.HIGH_RISK]: 'High Risk',
  [EKycTag.MEDIUM_RISK]: 'Medium Risk',
  [EKycTag.LOW_RISK]: 'Low Risk',
  [EKycTag.IP_COUNTRY_MISMATCH]: 'IP Country Mismatch',
  [EKycTag.PROHIBITED_COUNTRY]: 'Prohibited Country',
  [EKycTag.UNSUPPORTED_DOCUMENT_TYPE]: 'Unsupported Document Type',
  [EKycTag.ISSUING_COUNTRY_MISSING]: 'Issuing Country Missing',
  [EKycTag.IDENTITY_CHECK_NOT_PROCESSED]: 'Identity Check Not Processed',
  [EKycTag.DOCUMENT_CHECK_NOT_PROCESSED]: 'Document Check Not Processed'
})

export const kycTagOptions = Object.values(EKycTag).map((key) => ({
  value: key,
  label: EKycTagTxt[key]
}))

export enum EKycState {
  NO_KYC = 'NO_KYC',
  DOCUMENT_EDITED = 'DOCUMENT_EDITED',
  ANSWER_REQUIRED = 'ANSWER_REQUIRED',
  IDENTITY_DOCUMENT_SUBMITTED = 'IDENTITY_DOCUMENT_SUBMITTED',
  IDENTITY_CHECKED = 'IDENTITY_CHECKED',
  KYC_QUESTION_ANSWERED = 'KYC_QUESTION_ANSWERED',
  ADDRESS_REQUIRED = 'ADDRESS_REQUIRED',
  ADDRESS_PROVIDED = 'ADDRESS_PROVIDED',
  AML_CHECKED = 'AML_CHECKED',
  POA_REQUIRED = 'POA_REQUIRED',
  POA_SUBMITTED = 'POA_SUBMITTED',
  POA_VERIFIED = 'POA_VERIFIED',
  MANUAL_APPROVE_PENDING = 'MANUAL_APPROVE_PENDING',
  DONE = 'DONE',
  FAILED = 'FAILED'
}

export const EKycStateTxt = Object.freeze({
  [EKycState.NO_KYC]: 'No KYC',
  [EKycState.DOCUMENT_EDITED]: 'Document Edited',
  [EKycState.ANSWER_REQUIRED]: 'Answer Required',
  [EKycState.IDENTITY_DOCUMENT_SUBMITTED]: 'Identity Document Submitted',
  [EKycState.IDENTITY_CHECKED]: 'Identity Checked',
  [EKycState.KYC_QUESTION_ANSWERED]: 'KYC Question Answered',
  [EKycState.ADDRESS_REQUIRED]: 'Address Required',
  [EKycState.ADDRESS_PROVIDED]: 'Address Provided',
  [EKycState.AML_CHECKED]: 'AML Checked',
  [EKycState.POA_REQUIRED]: 'POA Required',
  [EKycState.POA_SUBMITTED]: 'POA Submitted',
  [EKycState.POA_VERIFIED]: 'POA Verified',
  [EKycState.MANUAL_APPROVE_PENDING]: 'Manual Approve Pending',
  [EKycState.DONE]: 'Done',
  [EKycState.FAILED]: 'Failed'
})

export const EKycStateOptions = Object.values(EKycState).map((key) => ({
  value: key,
  label: EKycStateTxt[key]
}))

export enum KYCDocumentType {
  DRIVING_LICENSE = 'driving_license',
  NATIONAL_IDENTITY_CARD = 'national_identity_card',
  PASSPORT = 'passport',
  PROOF_OF_ADDRESS = 'proof_of_address',
  PROOF_SOURCE_OF_FUND = 'proof_source_of_fund',
  OTHER = 'other'
}

export const KYCDocumentTypeLabel = {
  [KYCDocumentType.DRIVING_LICENSE]: 'Driving License',
  [KYCDocumentType.NATIONAL_IDENTITY_CARD]: 'National Identity Card',
  [KYCDocumentType.PASSPORT]: 'Passport',
  [KYCDocumentType.PROOF_OF_ADDRESS]: 'Proof of Address',
  [KYCDocumentType.PROOF_SOURCE_OF_FUND]: 'Proof Source of Fund',
  [KYCDocumentType.OTHER]: 'Other'
}
