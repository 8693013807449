import { graphql } from 'relay-runtime'

import { KybDocumentsQuery } from './__generated__/KybDocumentsQuery.graphql'

export type { KybDocumentsQuery }

export const kybDocumentsQuery = graphql`
  query KybDocumentsQuery($kybAttemptID: String!) {
    kybDocuments(kybAttemptID: $kybAttemptID) {
      documents {
        ...on KYBDocument {
          id
          createTime
          updateTime
          uploaderUserID
          url
          type
          comment
        }
      }
    }
  }
`
