import { graphql } from 'relay-runtime'

import { ConfirmRegistrationMutation } from './__generated__/ConfirmRegistrationMutation.graphql'

export type { ConfirmRegistrationMutation }

export const confirmRegistrationMutation = graphql`
  mutation ConfirmRegistrationMutation($email: String!) {
    confirmRegistration(email: $email) {
      code
      message
    }
  }
`
