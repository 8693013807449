import { graphql } from 'relay-runtime'

import { ExcludedWalletsQuery } from './__generated__/ExcludedWalletsQuery.graphql'

export type { ExcludedWalletsQuery }

export type ExcludedWalletEdge = DeepWriteable<DeepExclude<TArrayItem<ExcludedWalletsQuery['response']['excludedWallets']['edges']>, null>['node']>

export const excludedWalletsQuery = graphql`
  query ExcludedWalletsQuery(
    $after: Cursor
    $first: Int
    $before: Cursor
    $last: Int
    $where: ExcludedWalletWhereInput
  ) {
    excludedWallets(
      after: $after
      first: $first
      before: $before
      last: $last
      where: $where
    ) {
      ...on ExcludedWalletConnection {
        totalCount
        pageInfo {
          ...on PageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
        }
        edges {
          ...on ExcludedWalletEdge {
            cursor
            node {
              ...on ExcludedWallet {
                id
                isExcluded
                walletAddress
              }
            }
          }
        }
      }
    }
  }
`
