/**
 * @generated SignedSource<<d69516ce92a2d437b9788afb3d8d92bd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type StatusCode = "ABORTED" | "ALREADY_EXISTS" | "CANCELLED" | "DATA_LOSS" | "DEADLINE_EXCEEDED" | "FAILED_PRECONDITION" | "INTERNAL" | "INVALID_ARGUMENT" | "NOT_FOUND" | "OK" | "OUT_OF_RANGE" | "PERMISSION_DENIED" | "RESOURCE_EXHAUSTED" | "UNAUTHENTICATED" | "UNAVAILABLE" | "UNIMPLEMENTED" | "UNKNOWN" | "%future added value";
export type UpdateClientPaidAmountInput = {
  comment?: string | null;
  epoch: number;
  id: string;
  paidAmount: number;
};
export type UpdateClientPaidAmountMutation$variables = {
  input: UpdateClientPaidAmountInput;
};
export type UpdateClientPaidAmountMutation$data = {
  readonly updateClientPaidAmount: {
    readonly code: StatusCode;
    readonly message: string;
  };
};
export type UpdateClientPaidAmountMutation = {
  response: UpdateClientPaidAmountMutation$data;
  variables: UpdateClientPaidAmountMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "BaseStatus",
    "kind": "LinkedField",
    "name": "updateClientPaidAmount",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "code",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateClientPaidAmountMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateClientPaidAmountMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c7cd049ee002c13a3de14d7e7f361f74",
    "id": null,
    "metadata": {},
    "name": "UpdateClientPaidAmountMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateClientPaidAmountMutation(\n  $input: UpdateClientPaidAmountInput!\n) {\n  updateClientPaidAmount(input: $input) {\n    code\n    message\n  }\n}\n"
  }
};
})();

(node as any).hash = "8b9d35162bfbe3857aca5223c556afb3";

export default node;
