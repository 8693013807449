export const renderColumns = () => [
  {
    title: 'Comment',
    dataIndex: 'comment',
    key: 'comment'
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action'
  },
  {
    title: 'Previous status & details',
    dataIndex: 'previous-status-and-details',
    key: 'previous-status-and-details'
  },
  {
    title: 'New status & details',
    dataIndex: 'new-status-and-details',
    key: 'new-status-and-details'
  },
  {
    title: 'Added by',
    dataIndex: 'added-by',
    key: 'added-by'
  },
  {
    title: 'Added time',
    dataIndex: 'added-time',
    key: 'added-time'
  }
]
