import { Button, Space } from 'antd'
import { Link } from 'react-router-dom'
import { TKolReward } from 'src/graphql'
import { DialogCommissionRate, DialogEditBDInCharge } from 'src/partials'
import { EPaths, generate } from 'src/router'
import { NumberUtils, UserUtils } from 'src/utils'

interface Actions {
  onFresh?: () => void
}

export const renderColumns = ({ onFresh }: Actions) => [
  {
    title: 'Company Name',
    dataIndex: 'companyName',
    key: 'companyName',
    render: (_: any, record: TKolReward) => record?.account?.displayName || record?.account?.name
  },
  {
    title: 'Account ID',
    dataIndex: 'accountID',
    key: 'accountID'
    // render: (accountID: TKolReward['accountID']) => (
    //   <Paragraph copyable>{accountID}</Paragraph>
    // )
  },
  {
    title: 'Trading Volume(USD)',
    dataIndex: 'tradingVolume',
    key: 'tradingVolume',
    render: (val: TKolReward['tradingVolume'], record: TKolReward) => NumberUtils.formatNumber(Number(val?.slice(0, -16)) / 100, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  },
  {
    title: 'Fee',
    dataIndex: 'fee',
    key: 'fee',
    render: (val: TKolReward['fee'], record: TKolReward) => NumberUtils.formatNumber(val ? val / 1e6 : 0, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  },
  {
    title: 'Commission Rate',
    dataIndex: 'commissionRate',
    key: 'commissionRate',
    render: (_: any, record: TKolReward) => (
      <div className="fx fx-ai-center gap-2">
        <DialogCommissionRate
          accountID={record.accountID}
          fieldChange="commissionRate"
          afterClose={(fresh) => fresh && onFresh?.()}
        >
          {record?.account?.kolConfigs?.commissionRate ? Number(record?.account?.kolConfigs?.commissionRate) / 100 + '%' : ''}
        </DialogCommissionRate>
      </div>
    )
  },
  {
    title: 'Epoch Earnings (USD)',
    dataIndex: 'earningAmount',
    key: 'earningAmount',
    render: (val: TKolReward['earningAmount'], record: TKolReward) => NumberUtils.formatNumber(val ? val / 1e6 : 0, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  },
  {
    title: 'Payment',
    dataIndex: 'payment',
    key: 'payment',
    render: (_: any, record: TKolReward) => (
      <Space>
        <Button
          size="small"
          type="link"
        >
          <Link
            to={generate([EPaths.BD_INST_PAYMENT_DETAILS, {
              accountID: record.accountID
            }])}
          >
            See details
          </Link>
        </Button>
      </Space>
    )
  },
  {
    title: 'BD in charge',
    dataIndex: 'bd_in_charge',
    key: 'bd_in_charge',
    render: (_: any, record: TKolReward) => (
      <div className="fx fx-ai-center gap-2">
        <DialogEditBDInCharge
          accountID={record.accountID}
          bdInCharge={record?.account?.kolConfigs?.bdsInCharge?.map((bd) => bd.id)}
          afterClose={(fresh) => fresh && onFresh?.()}
        >
          {!!record?.account?.kolConfigs?.bdsInCharge?.length && (
            <div className="fx-column gap-1">
              {record?.account?.kolConfigs?.bdsInCharge?.map((bd) => (
                <div key={bd.id}>{UserUtils.getFullName(bd)}</div>
              ))}
            </div>
          )}
        </DialogEditBDInCharge>
      </div>
    )
  }
]
