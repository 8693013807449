import { graphql } from 'relay-runtime'

import { BdActionLogsQuery } from './__generated__/BdActionLogsQuery.graphql'

export type { BdActionLogsQuery }

export type TBdActionLog = DeepWriteable<DeepExclude<TArrayItem<BdActionLogsQuery['response']['bdActionLogs']['edges']>, null>['node']>

export const bdActionLogsQuery = graphql`
  query BdActionLogsQuery(
    $after: Cursor
    $first: Int
    $before: Cursor
    $last: Int
    $where: BDActionLogWhereInput
  ) {
    bdActionLogs(
      after: $after
      first: $first
      before: $before
      last: $last
      where: $where
    ) {
        ...on BDActionLogConnection {
          totalCount
          pageInfo {
            ...on PageInfo {
              hasNextPage
              hasPreviousPage
              startCursor
              endCursor
            }
          }
          edges {
            ...on BDActionLogEdge {
              cursor
              node {
                ...on BDActionLog {
                  id
                  createTime
                  updateTime
                  bdID
                  epochID
                  action
                  actorID
                  data
                  comment
                  bd {
                    ...on AdminPortalUser {
                      id
                      firstName
                      lastName
                      email
                    }
                  }
                  actor {
                    ...on AdminPortalUser {
                      id
                      firstName
                      lastName
                      email
                    }
                  }
                  epoch {
                    ...on KOLRewardEpoch { id }
                  }
                }
              }
            }
          }
        }
    }
  }
`
