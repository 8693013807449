import { ConfirmRegistrationMutation, TUser, TUserIdentity, UpdateUserMutation, UserDetailsQuery, UserIdentityQuery, confirmRegistrationMutation, updateUserMutation, userDetailsQuery, userIdentityQuery } from 'src/graphql'
import { EdgeGraphqlService } from 'src/services'

export class UserApi {
  /**
   * SECTION: Queries
   */

  static find(id: Required<DeepExclude<UserDetailsQuery['variables'], null>>['where']['id']) {
    return EdgeGraphqlService.queryAsPromise<UserDetailsQuery>(userDetailsQuery, { where: { id } }).then(
      (response) => {
        const user = response.users.edges?.[0]?.node as TUser
        if (!user) {
          throw new Error('User not found')
        }

        return user
      }
    )
  }

  static userIdentity(variables: UserIdentityQuery['variables']) {
    return EdgeGraphqlService.queryAsPromise<UserIdentityQuery>(userIdentityQuery, variables).then(
      (response) => response.userIdentity.userIdentity as TUserIdentity
    )
  }

  /**
   * SECTION: Mutations
   */

  static update(variables: UpdateUserMutation['variables']) {
    return EdgeGraphqlService.mutationAsPromise<UpdateUserMutation>(updateUserMutation, variables).then(
      (response) => response.updateUserByAdminPortal
    )
  }

  static confirmRegistration(variables: ConfirmRegistrationMutation['variables']) {
    return EdgeGraphqlService.mutationAsPromise<ConfirmRegistrationMutation>(confirmRegistrationMutation, variables).then(
      (response) => response.confirmRegistration
    )
  }
}
