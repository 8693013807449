import { Badge, Tag } from 'antd'
import { TUserPii, TUserPiiOverview } from 'src/graphql'
import { EKycStatus, EKycStatusBadge, EKycStatusTxt, EKycTag, EKycTagTxt } from 'src/interfaces'

export class KycUtils {
  static renderTags(kycTags?: NonNullable<TUserPiiOverview['currentKyc']>['kycTags'] | null) {
    return kycTags?.map((tag) => (
      <Tag
        key={tag.id}
        color={({
          HIGH_RISK: 'red',
          LOW_RISK: 'green',
          MEDIUM_RISK: 'yellow'
        })[String(tag.value)]}
      >
        {EKycTagTxt[tag.value as EKycTag] || tag.value}
      </Tag>
    ))
  }

  static renderKycStatusBadge = (status?: string) => {
    const badge = EKycStatusBadge[status as EKycStatus] || 'default'
    const statusTxt = EKycStatusTxt[status as EKycStatus] || status
    return <Badge status={badge} className="wp-no-wrap" text={statusTxt}/>
  }

  static canStartReview(user?: TUserPii | null) {
    return (
      user?.currentKyc?.state === 'MANUAL_APPROVE_PENDING' ||
      user?.kycReviewState === 'APPLICATION_ONGOING'
    )
  }

  static canOnHold(user?: TUserPii | null) {
    return true
  }

  static canApprove(user?: TUserPii | null) {
    return true
  }

  static canDeny(user?: TUserPii | null) {
    return true
  }
}
