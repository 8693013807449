import { graphql } from 'relay-runtime'

import { BusinessAccountIdentitiesQuery } from './__generated__/BusinessAccountIdentitiesQuery.graphql'

export type { BusinessAccountIdentitiesQuery }

export type TBusinessUserPiiOverview = DeepWriteable<DeepExclude<TArrayItem<BusinessAccountIdentitiesQuery['response']['businessAccountIdentities']['edges']>, null>['node']>

export const businessAccountIdentitiesQuery = graphql`
  query BusinessAccountIdentitiesQuery(
    $after: Cursor
    $first: Int
    $before: Cursor
    $last: Int
    $where: BusinessAccountIdentityWhereInput
  ) {
    businessAccountIdentities(
      after: $after
      first: $first
      before: $before
      last: $last
      where: $where
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
        ...on BusinessAccountIdentity {
          id
          createTime
          updateTime
          accountID
          fullLegalName
          email
          currentKyb {
            ...on KYBAttempt {
              id
              state
            }
          }
        }
      }
      }
    }
  }
`
