import { BehaviorSubject } from 'rxjs'
import { KolTrackerApi } from 'src/api'
import { ignore } from 'src/constants'
import { TKolRewardEpoch } from 'src/graphql'
import { WithOutNextComplete } from 'types/rxjs'

export const KOLEpochsStore = new (
  class {
    private readonly _kolEpochs$ = new BehaviorSubject<TKolRewardEpoch[]>([])
    get kolEpochs$(): WithOutNextComplete<typeof this._kolEpochs$> {
      return this._kolEpochs$
    }

    private readonly _kolEpochsOptions$ = new BehaviorSubject<{value: string; label: string}[]>([])
    get kolEpochsOptions$(): WithOutNextComplete<typeof this._kolEpochsOptions$> {
      return this._kolEpochsOptions$
    }

    private readonly _loading$ = new BehaviorSubject(false)
    get loading$(): WithOutNextComplete<typeof this._loading$> {
      return this._loading$
    }

    constructor() {
      this.fetchKolEpochs()

      this._kolEpochs$.subscribe((kolEpochs) => {
        this._kolEpochsOptions$.next(kolEpochs.map((epoch) => ({
          value: epoch.id,
          label: String(epoch.epoch)
        })))
      })
    }

    fetchKolEpochs() {
      this._loading$.next(true)
      KolTrackerApi.kolEpochs({})
        .then((kolEpochs) => this._kolEpochs$.next(
          Array.ensure(kolEpochs.edges?.map((edge) => edge?.node)) as TKolRewardEpoch[]
        ))
        .catch((error) => {
          ignore(error)
        })
        .finally(() => {
          this._loading$.next(false)
        })
    }
  }
)()
