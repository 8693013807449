import { graphql } from 'relay-runtime'

import { AdminUpdateUserIdentityMutation } from './__generated__/AdminUpdateUserIdentityMutation.graphql'

export type { AdminUpdateUserIdentityMutation }

export const adminUpdateUserIdentityMutation = graphql`
  mutation AdminUpdateUserIdentityMutation($input: UpdateUserIdentityInput!) {
    adminUpdateUserIdentity(input: $input) {
      data {
        ...on UserIdentity {
          currentKyc {
            ...on KYCAttempt {
              id
              createTime
              updateTime
            }
          }
        }
      }
    }
  }
`
