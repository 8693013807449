/**
 * @generated SignedSource<<501c594c69f9bfbe1bd4e84dcf459e63>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type StatusCode = "ABORTED" | "ALREADY_EXISTS" | "CANCELLED" | "DATA_LOSS" | "DEADLINE_EXCEEDED" | "FAILED_PRECONDITION" | "INTERNAL" | "INVALID_ARGUMENT" | "NOT_FOUND" | "OK" | "OUT_OF_RANGE" | "PERMISSION_DENIED" | "RESOURCE_EXHAUSTED" | "UNAUTHENTICATED" | "UNAVAILABLE" | "UNIMPLEMENTED" | "UNKNOWN" | "%future added value";
export type ConfirmRegistrationMutation$variables = {
  email: string;
};
export type ConfirmRegistrationMutation$data = {
  readonly confirmRegistration: {
    readonly code: StatusCode;
    readonly message: string;
  };
};
export type ConfirmRegistrationMutation = {
  response: ConfirmRegistrationMutation$data;
  variables: ConfirmRegistrationMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      }
    ],
    "concreteType": "BaseStatus",
    "kind": "LinkedField",
    "name": "confirmRegistration",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "code",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ConfirmRegistrationMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ConfirmRegistrationMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9821839b66c710124335b17e9a2cd6a7",
    "id": null,
    "metadata": {},
    "name": "ConfirmRegistrationMutation",
    "operationKind": "mutation",
    "text": "mutation ConfirmRegistrationMutation(\n  $email: String!\n) {\n  confirmRegistration(email: $email) {\n    code\n    message\n  }\n}\n"
  }
};
})();

(node as any).hash = "7993f24559e6e177fb75b0661e34763e";

export default node;
