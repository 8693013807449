const env = (
  typeof (window as any)?.env === 'undefined'
    ? process.env
    : (window as any).env as { [key: string]: string }
) || {} as { [key: string]: string }

export const EEnv = Object.freeze({
  NODE_ENV: env.NODE_ENV as string,
  REACT_APP_PUBLIC_ENV: env.REACT_APP_PUBLIC_ENV as string,
  REACT_APP_ENABLE_SW: env.REACT_APP_ENABLE_SW as string,
  REACT_APP_TINYMCE_KEY: env.REACT_APP_TINYMCE_KEY as string,
  REACT_APP_EDGE_HOST: env.REACT_APP_EDGE_HOST?.endsWith?.('/')
    ? env.REACT_APP_EDGE_HOST.slice(0, -1)
    : env.REACT_APP_EDGE_HOST,
  REACT_APP_REWARD_HOST: env.REACT_APP_REWARD_HOST as string
})
