/**
 * @generated SignedSource<<50caa28d931123fc13edbc7ab92933ba>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type InitUserIdentityInput = {
  email: string;
  isBusiness: boolean;
};
export type InitUserIdentityMutation$variables = {
  input: InitUserIdentityInput;
};
export type InitUserIdentityMutation$data = {
  readonly initUserIdentity: {
    readonly data: {
      readonly id: string;
      readonly kycAttemptID: string | null;
    };
  };
};
export type InitUserIdentityMutation = {
  response: InitUserIdentityMutation$data;
  variables: InitUserIdentityMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UserIdentityStatus",
    "kind": "LinkedField",
    "name": "initUserIdentity",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserIdentity",
        "kind": "LinkedField",
        "name": "data",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "kycAttemptID",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InitUserIdentityMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InitUserIdentityMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9838dc38f520c70418922e4d6735d98a",
    "id": null,
    "metadata": {},
    "name": "InitUserIdentityMutation",
    "operationKind": "mutation",
    "text": "mutation InitUserIdentityMutation(\n  $input: InitUserIdentityInput!\n) {\n  initUserIdentity(input: $input) {\n    data {\n      id\n      kycAttemptID\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7a03b0e1234c5074421e6a85c65c0067";

export default node;
