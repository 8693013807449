import { InboxOutlined } from '@ant-design/icons'
import { Button, Form, Input, Modal, Select, Upload, UploadFile, UploadProps } from 'antd'
import { ComponentProps, FC, useCallback, useEffect, useState } from 'react'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { PiiApi } from 'src/api'
import { TAdminInvitee, TUserPii } from 'src/graphql'
import { useAsRef, useUnsubscribe } from 'src/hooks'
import { KYCDocumentType, KYCDocumentTypeLabel } from 'src/interfaces'
import { MessageService, S3Service } from 'src/services'

// const MAX_SIZE_FILE_UPLOAD = 5 * 1024 * 1024

type FieldType = {
  documentType: KYCDocumentType
  document: string
  comment: string
}

export const DialogKycDocument: FC<Omit<
  ComponentProps<typeof Modal>,
  'onOk' | 'afterClose'
> & {
  user: TUserPii
  btnProps?: ComponentProps<typeof Button>
  afterClose?: (saved?: boolean) => any
}> = ({
  user,
  btnProps,
  ...props
}) => {
  const unsubscribe$ = useUnsubscribe()
  const [loading, setLoading] = useState(false)
  const [invisible, setInvisible] = useState<boolean | TAdminInvitee>(true)

  useEffect(() => {
    setInvisible(!props.open)
  }, [props.open])

  const afterCloseRef = useAsRef(props.afterClose)

  const [form] = Form.useForm<FieldType>()
  const [file, setFile] = useState<UploadFile>()
  useEffect(() => {
    form.resetFields()
    setFile(undefined)
  }, [form, invisible])

  const close = useCallback(() => {
    setInvisible(true)
  }, [])

  const uploadProps: UploadProps = {
    multiple: false,
    onRemove: (file) => {
      // const index = fileList.indexOf(file)
      // const newFileList = fileList.slice()
      // newFileList.splice(index, 1)
      // setFileList(newFileList)
      setFile(undefined)
    },
    beforeUpload: (file) => {
      // if (file.size > MAX_SIZE_FILE_UPLOAD) {
      //   MessageService.error('File size exceeds the limit of 5MB')
      //   return true
      // }
      // setFileList(prev => ([...prev, file]))
      // return false
      setFile(file)
      return false
    },
    fileList: file ? [file] : [],
    accept: 'image/*, application/pdf'
  }

  const submit = useCallback((values: FieldType) => {
    const kycAttemptID = user.currentKyc?.id
    if (!kycAttemptID || !file) {
      return
    }
    setLoading(true)
    const promise = (async () => {
      const { key, signedURL } = await PiiApi.kycReviewDocumentsSignedURL({
        input: {
          kycAttemptID,
          documentType: values.documentType,
          fileName: file.name
        }
      })
      await S3Service.presignedUpload(signedURL, file as unknown as File)
      const input = {
        comment: values.comment,
        documentType: values.documentType,
        kycAttemptID,
        url: key
      }
      return PiiApi.createKYCReviewDocument({ input })
    })()
    from(promise)
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          MessageService.error(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(() => {
        MessageService.info('Upload Document successfully')
        close()
        afterCloseRef.current?.(true)
      })
  }, [afterCloseRef, close, file, unsubscribe$, user.currentKyc?.id])

  return (
    <>
      <Button
        type="primary"
        {...btnProps}
        onClick={() => setInvisible(false)}
      >
        {props.children || 'Upload'}
      </Button>

      <Modal
        title={props.title || 'Upload'}
        open={!invisible}
        centered
        closable={!loading} // display X icon
        keyboard={false} // disable close on press ESC
        maskClosable={false} // disable close on click outside
        okButtonProps={{ disabled: loading }}
        cancelButtonProps={{ disabled: loading }}
        okText="Ok"
        cancelText="Cancel"
        onOk={() => form.submit()}
        onCancel={close}
      >
        {!invisible && (
          <Form
            form={form}
            name="kyc-document"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            onFinish={submit}
            autoComplete="off"
          >
            <Form.Item<FieldType>
              label="Document type"
              name="documentType"
              rules={[{ required: true }]}
            >
              <Select allowClear>
                {Object.values(KYCDocumentType).map((value) => (
                  <Select.Option key={value} value={value}>{KYCDocumentTypeLabel[value]}</Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item<FieldType>
              label="Document"
              name="document"
              rules={[{ required: true }]}
            >
              <Upload.Dragger {...uploadProps}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined/>
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">Please upload pdf, jpg or png within 5mb.</p>
              </Upload.Dragger>
            </Form.Item>

            <Form.Item<FieldType>
              label="Comment"
              name="comment"
              rules={[{ required: true }]}
            >
              <Input.TextArea/>
            </Form.Item>
          </Form>
        )}
      </Modal>
    </>
  )
}
