import { accountKolRewardsQuery, AccountKolRewardsQuery, BdPerformanceQuery, bdPerformanceQuery, kolRewardEpochesQuery, KolRewardEpochesQuery, KolStatisticsQuery, kolStatisticsQuery, updateBDConfigMutation, UpdateBDConfigMutation, UpdateClientPaidAmountMutation, updateClientPaidAmountMutation, UpdateKOLConfigMutation, updateKOLConfigMutation } from 'src/graphql'
import { EdgeAxios, EdgeGraphqlService } from 'src/services'
import { TAxiosResponseData } from 'types/axios'
export class KolTrackerApi {
  static kol(): TAxiosResponseData<Blob> {
    return EdgeAxios.get('/kol', {
      responseType: 'blob'
    })
  }

  /**
   * SECTION: Queries
   */

  static kolEpochs(variables: KolRewardEpochesQuery['variables']) {
    return EdgeGraphqlService.queryAsPromise<KolRewardEpochesQuery>(kolRewardEpochesQuery, variables).then(
      (response) => response.kolRewardEpoches
    )
  }

  static kolStatistics(variables: KolStatisticsQuery['variables']) {
    return EdgeGraphqlService.queryAsPromise<KolStatisticsQuery>(kolStatisticsQuery, variables).then(
      (response) => response.kolStatistics
    )
  }

  static bdPerformance(variables: BdPerformanceQuery['variables']) {
    return EdgeGraphqlService.queryAsPromise<BdPerformanceQuery>(bdPerformanceQuery, variables).then(
      (response) => response.bdPerformance
    )
  }

  static accountKolRewards(variables: AccountKolRewardsQuery['variables']) {
    return EdgeGraphqlService.queryAsPromise<AccountKolRewardsQuery>(accountKolRewardsQuery, variables).then(
      (response) => response.users.edges?.[0]?.node?.account?.kolRewards
    )
  }

  /**
   * SECTION: Mutations
   */

  static updateKOLConfig(variables: UpdateKOLConfigMutation['variables']) {
    return EdgeGraphqlService.mutationAsPromise<UpdateKOLConfigMutation>(updateKOLConfigMutation, variables).then(
      (response) => response.updateKOLConfig
    )
  }

  static updateBDConfig(variables: UpdateBDConfigMutation['variables']) {
    return EdgeGraphqlService.mutationAsPromise<UpdateBDConfigMutation>(updateBDConfigMutation, variables).then(
      (response) => response.updateBDConfig
    )
  }

  static updateClientPaidAmount(variables: UpdateClientPaidAmountMutation['variables']) {
    return EdgeGraphqlService.mutationAsPromise<UpdateClientPaidAmountMutation>(updateClientPaidAmountMutation, variables).then(
      (response) => response.updateClientPaidAmount
    )
  }
}
