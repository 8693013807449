import { graphql } from 'relay-runtime'

import { CreateDocumentMutation } from './__generated__/CreateDocumentMutation.graphql'

export type { CreateDocumentMutation }

export const createDocumentMutation = graphql`
  mutation CreateDocumentMutation($input: CreateDocumentInput!) {
    createDocument(input: $input) {
      code
      message
      document {
        ...on Document {
          id
          createTime
          updateTime
          name
          path
          accountID
          userID
          visibility
          account {
            ...on Account {
              id
              createTime
              updateTime
            }
          }
          user {
            ...on User {
              id
              createTime
              updateTime
            }
          }
        }
      }
    }
  }
`
