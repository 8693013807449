import { graphql } from 'relay-runtime'

import { AccountKolRewardsQuery } from './__generated__/AccountKolRewardsQuery.graphql'

export type { AccountKolRewardsQuery }

export type TAccountKolRewards = DeepPartial<DeepExclude<AccountKolRewardsQuery, null>['response']['users']['edges'][number]['node']['account']['kolRewards'][number]>

export const accountKolRewardsQuery = graphql`
  query AccountKolRewardsQuery(
    $after: Cursor
    $first: Int
    $before: Cursor
    $last: Int
    $where: UserWhereInput
  ) {
    users(
      after: $after
      first: $first
      before: $before
      last: $last
      where: $where
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          ...on User {
            account {
              ...on Account {
                kolRewards {
                  ...on KOLReward {
                    id
                    createTime
                    updateTime
                    accountID
                    epochID
                    earningAmount
                    dueAmount
                    paidAmount
                    tradingVolume
                    directTradingVolume
                    indirectTradingVolume
                    directFee
                    indirectFee
                    fee
                    metadata
                    comment
                    epoch {
                      ...on KOLRewardEpoch {
                        id
                        epoch
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
