import { TBdPerformance } from 'src/graphql'
import { DialogCommissionRate } from 'src/partials'
import { NumberUtils } from 'src/utils'

interface Actions {
  onFresh?: () => void
}

export const renderColumns = ({ onFresh }: Actions) => [
  {
    key: 'email',
    title: 'BD email',
    dataIndex: 'email'
  },
  {
    key: 'clientType',
    title: 'Client Type',
    dataIndex: 'clientType',
    render: (val: TBdPerformance['clientType'], record: TBdPerformance) => val
  },
  {
    key: 'commissionRate',
    title: 'Commission Rate',
    dataIndex: 'commissionRate',
    render: (val: TBdPerformance['commissionRate'], record: TBdPerformance) => (
      <div className="fx fx-ai-center gap-2">
        <DialogCommissionRate
          isBd
          fieldChange="commissionRate"
          accountID={record.id}
          afterClose={(fresh) => fresh && onFresh?.()}
        >
          {val ? Number(val) / 100 : ''}%
        </DialogCommissionRate>
      </div>
    )
  },
  {
    key: 'numberOfClientsInCharge',
    title: 'No. of Clients on charge',
    dataIndex: 'numberOfClientsInCharge'
  },
  {
    key: 'clientEarnings',
    title: 'Clients Earnings',
    dataIndex: 'clientEarnings',
    render: (val: TBdPerformance['clientEarnings'], record: TBdPerformance) => NumberUtils.formatNumber(Number(val) / 1e6, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  },
  {
    key: 'bdEarnings',
    title: 'BD Earnings',
    dataIndex: 'bdEarnings',
    render: (val: TBdPerformance['bdEarnings'], record: TBdPerformance) => NumberUtils.formatNumber(Number(val) / 1e6, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  }
]
