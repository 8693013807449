import { graphql } from 'relay-runtime'

import { KolActionLogsQuery } from './__generated__/KolActionLogsQuery.graphql'

export type { KolActionLogsQuery }

export type TKolActionLog = DeepWriteable<DeepExclude<TArrayItem<KolActionLogsQuery['response']['kolActionLogs']['edges']>, null>['node']>

export const kolActionLogsQuery = graphql`
  query KolActionLogsQuery(
    $after: Cursor
    $first: Int
    $before: Cursor
    $last: Int
    $where: KOLActionLogWhereInput
  ) {
    kolActionLogs(
      after: $after
      first: $first
      before: $before
      last: $last
      where: $where
    ) {
        ...on KOLActionLogConnection {
          totalCount
          pageInfo {
            ...on PageInfo {
              hasNextPage
              hasPreviousPage
              startCursor
              endCursor
            }
          }
          edges {
            ...on KOLActionLogEdge {
              cursor
              node {
                ...on KOLActionLog {
                  id
                  createTime
                  updateTime
                  accountID
                  epochID
                  bdID
                  action
                  data
                  comment
                  kol {
                    ...on Account {
                      id
                      isBusiness
                      name
                      displayName
                      users {
                        ...on User {
                          id
                          email
                          firstName
                          lastName
                        }
                      }
                    }
                  }
                  bd {
                    ...on AdminPortalUser {
                      id
                      firstName
                      lastName
                      email
                    }
                  }
                  epoch {
                    ...on KOLRewardEpoch { id }
                  }
                }
              }
            }
          }
        }
    }
  }
`
