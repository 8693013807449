import { GiftOutlined } from '@ant-design/icons'
import { Input, Table } from 'antd'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { EcoRewardApi, IPointDistributionData } from 'src/api'
import { useUnsubscribe } from 'src/hooks'
import { DialogBulkReward } from 'src/partials'
import { EPaths } from 'src/router'
import { BreadcrumbService, ClipboardService, MessageService } from 'src/services'

export const KOLPointDistribution: FC = () => {
  const unsubscribe$ = useUnsubscribe()

  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = [
      {
        route: EPaths.BD_KOL_TRACKER,
        label: 'KOL Tracker'
      },
      {
        route: EPaths.BD_KOL_POINT_DISTRIBUTION,
        label: 'Point Distributions'
      }
    ]
    return () => {
      BreadcrumbService.items = []
    }
  }, [])

  const [listPointDistribution, setListPointDistribution] = useState<IPointDistributionData[]>([])
  const [loading, setLoading] = useState(false)
  const [searchText, setSearchText] = useState('')

  const dataSource = useMemo(() => {
    return listPointDistribution.filter((item) => {
      return item.account_id.toLowerCase().includes(searchText.trim().toLowerCase())
    })
  }, [listPointDistribution, searchText])

  const fetchListPointDistribution = useCallback(() => {
    setLoading(true)
    from(EcoRewardApi.getListPointDistributions())
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          MessageService.error(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe((res) => {
        setListPointDistribution(Array.ensure(res.data.point_distributions))
      })
  }, [unsubscribe$])

  useEffect(() => {
    fetchListPointDistribution()
  }, [fetchListPointDistribution])

  return (
    <section className="fx fx-column fx-extend">
      <div className="fx fx-jc-space-between mb-2">
        <div className="fx fx-extend fx-ai-center gap-2">
          <Input.Search
            allowClear
            placeholder="Search..."
            style={{ flex: '0 0 200px' }}
            readOnly={loading}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value.trim())}
            onSearch={() => fetchListPointDistribution()}
          />
        </div>

        <div className="fx fx-ai-center gap-2">
          <DialogBulkReward
            afterClose={(fresh) => fresh && fetchListPointDistribution()}
          >
            Bulk Reward
            <GiftOutlined/>
          </DialogBulkReward>
        </div>
      </div>

      <Table
        bordered
        rowKey="id"
        className="fx-extend"
        loading={loading}
        dataSource={dataSource}
        columns={[
          {
            title: 'Account ID',
            dataIndex: 'account_id',
            key: 'accountID',
            width: '50%',
            sorter: (a, b) => a.account_id.toLowerCase().localeCompare(b.account_id.toLowerCase()),
            render: (accountID: string) => (
              <div
                className="pointer"
                onClick={() => ClipboardService.copy(accountID)}
              >
                {accountID}
              </div>
            )
          },
          {
            title: 'Total points',
            dataIndex: 'total_point',
            key: 'totalPoints',
            width: '50%',
            sorter: (a, b) => a.total_point - b.total_point
          }
        ]}
        tableLayout="fixed"
        scroll={{ x: true }}
      />
    </section>
  )
}
