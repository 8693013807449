import { FC, useEffect } from 'react'
import { EPaths } from 'src/router'
import { BreadcrumbService } from 'src/services'

const PAGE_TITLE = 'Dashboard'

export const Dashboard: FC = () => {
  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = [{
      route: EPaths.DASHBOARD,
      label: PAGE_TITLE
    }]
    return () => {
      BreadcrumbService.items = []
    }
  }, [])

  return (
    <section className="fx fx-column gap-2">
      Welcome!
    </section>
  )
}
