import { graphql } from 'relay-runtime'

import { UpdateClientPaidAmountMutation } from './__generated__/UpdateClientPaidAmountMutation.graphql'

export type { UpdateClientPaidAmountMutation }

export const updateClientPaidAmountMutation = graphql`
  mutation UpdateClientPaidAmountMutation($input: UpdateClientPaidAmountInput!) {
    updateClientPaidAmount(input: $input) {
      code
      message
    }
  }
`
