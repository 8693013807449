import { Button, Input, Select, Table } from 'antd'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { catchError, EMPTY, finalize, from, takeUntil } from 'rxjs'
import { KolTrackerApi } from 'src/api'
import { useBehaviorMapper, useUnsubscribe } from 'src/hooks'
import { EPaths } from 'src/router'
import { BreadcrumbService, MessageService } from 'src/services'
import { KOLEpochsStore } from 'src/store'
import { renderColumns } from './columns'

export const BDPerformance: FC = () => {
  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = [{
      route: EPaths.BD_KOL_TRACKER,
      label: 'BD Performance'
    }]
    return () => {
      BreadcrumbService.items = []
    }
  }, [])

  const unsubscribe$ = useUnsubscribe()

  const kolEpochsOptions = useBehaviorMapper(KOLEpochsStore.kolEpochsOptions$)
  useEffect(() => {
    KOLEpochsStore.fetchKolEpochs()
  }, [])

  const [loading, setLoading] = useState(false)
  const [bdPerformance, setBdPerformance] = useState<Partial<Awaited<ReturnType<typeof KolTrackerApi.bdPerformance>>>>({})
  const dataSource = useMemo(() => bdPerformance.data || [], [bdPerformance.data])
  const [currentPage, setCurrentPage] = useState(1)
  const [keyword, setKeyword] = useState('')
  const [filter, setFilter] = useState<Pick<Parameters<typeof KolTrackerApi.bdPerformance>[0]['input'], 'query' | 'epochID'>>({ epochID: '' })

  const fetch = useCallback((input: Omit<Parameters<typeof KolTrackerApi.bdPerformance>[0]['input'], 'epochID'>) => {
    if (!filter.epochID) {
      return
    }

    setLoading(true)
    from(KolTrackerApi.bdPerformance({ input: { ...input, ...filter } }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          MessageService.error(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(setBdPerformance)
  }, [filter, unsubscribe$])

  useEffect(() => {
    setCurrentPage(1)
  }, [filter])

  useEffect(() => {
    fetch({
      pageNumber: currentPage,
      pageSize: 10
    })
  }, [currentPage, fetch])

  useEffect(() => {
    if (!filter.epochID && kolEpochsOptions?.[0]?.value) {
      setFilter((prev) => ({
        ...prev,
        epochID: kolEpochsOptions?.[0]?.value
      }))
    }
  }, [filter.epochID, kolEpochsOptions])

  return (
    <section className="fx fx-column fx-extend gap-2">
      <div className="fx fx-jc-space-between">
        <div className="fx fx-extend fx-ai-center gap-2 label-1">
          BD Performance
          <Select
            allowClear
            style={{ flex: '0 0 100px' }}
            placeholder="Epoch #"
            value={filter.epochID}
            options={kolEpochsOptions}
            onChange={(value) => setFilter((prev) => ({
              ...prev,
              epochID: value
            }))}
          />
        </div>

        <div className="fx fx-ai-center fx-jc-end gap-2">
          <Input.Search
            allowClear
            placeholder="Search..."
            style={{ flex: '0 0 200px' }}
            readOnly={loading}
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            onSearch={() => setFilter((prev) => ({
              ...prev,
              query: keyword
            }))}
          />
          <Link to={EPaths.BD_COMMISSION_RATE_HISTORY}>
            <Button type="primary">
              See history
            </Button>
          </Link>
        </div>
      </div>

      <Table
        bordered
        rowKey="id"
        className="fx-extend"
        loading={loading}
        dataSource={dataSource}
        columns={renderColumns({
          onFresh: () => fetch({
            pageNumber: currentPage,
            pageSize: 10
          })
        })}
        tableLayout="fixed"
        pagination={{
          defaultPageSize: 10,
          total: bdPerformance.total,
          onChange: (page) => setCurrentPage(page)
        }}
        scroll={{ x: true }}
      />
    </section>
  )
}
