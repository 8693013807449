/**
 * @generated SignedSource<<f0bc6054f11158227c75bed325565210>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type KYBDocumentType = "BENEFICIAL_OWNER_DECLARATION" | "BOARD_RESOLUTION" | "BUSINESS_DETAILS" | "CERT_INCORPORATION" | "CERT_INCUMBENCY" | "CORPORATE_STRUCTURE_OWNERSHIP" | "MEMORANDUM_ARTICLES" | "OTHER" | "PROOF_SOURCE_FUNDS" | "REGISTER_OF_DIRECTORS" | "REGISTER_OF_SHAREHOLDERS" | "STAKEHOLDER_DETAILS" | "%future added value";
export type KybDocumentsQuery$variables = {
  kybAttemptID: string;
};
export type KybDocumentsQuery$data = {
  readonly kybDocuments: {
    readonly documents: ReadonlyArray<{
      readonly comment: string | null;
      readonly createTime: any | null;
      readonly id: string;
      readonly type: KYBDocumentType;
      readonly updateTime: any | null;
      readonly uploaderUserID: string;
      readonly url: string;
    }>;
  };
};
export type KybDocumentsQuery = {
  response: KybDocumentsQuery$data;
  variables: KybDocumentsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "kybAttemptID"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "kybAttemptID",
        "variableName": "kybAttemptID"
      }
    ],
    "concreteType": "KYBDocumentsOutput",
    "kind": "LinkedField",
    "name": "kybDocuments",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "KYBDocument",
        "kind": "LinkedField",
        "name": "documents",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updateTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "uploaderUserID",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "comment",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "KybDocumentsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "KybDocumentsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f8a72d5bc332c7710435dc1e1b5a29fd",
    "id": null,
    "metadata": {},
    "name": "KybDocumentsQuery",
    "operationKind": "query",
    "text": "query KybDocumentsQuery(\n  $kybAttemptID: String!\n) {\n  kybDocuments(kybAttemptID: $kybAttemptID) {\n    documents {\n      id\n      createTime\n      updateTime\n      uploaderUserID\n      url\n      type\n      comment\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "dbdde28ab8ed159ad4db9c210309163b";

export default node;
