import { Descriptions, DescriptionsProps } from 'antd'
import { FC, useMemo } from 'react'
import { TBusinessUserPii } from 'src/graphql'
import { KybUtils } from 'src/utils'

interface IProps {
  user?: TBusinessUserPii | null
  onUpdated?: () => void
}

export const BusinessKybApplicantInfo: FC<IProps> = ({ user, onUpdated }) => {
  const items: DescriptionsProps['items'] = useMemo(
    () => [
      {
        label: 'Status',
        children: KybUtils.renderKybStatusBadge(user?.finalState)
      }
    ].map((el, index) => ({
      ...el,
      key: index,
      style: { width: '50%' }
    })),
    [user]
  )

  return (
    <section>
      <Descriptions
        layout="vertical"
        size="small"
        column={2}
        bordered
        items={items}
      />
    </section>
  )
}
