/**
 * @generated SignedSource<<3f192707835d3fd6c4bee239a27b5386>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type InitBusinessAccountIdentityMutation$variables = {
  email: string;
};
export type InitBusinessAccountIdentityMutation$data = {
  readonly initBusinessAccountIdentity: {
    readonly data: {
      readonly accountID: string | null;
      readonly id: string;
    };
  };
};
export type InitBusinessAccountIdentityMutation = {
  response: InitBusinessAccountIdentityMutation$data;
  variables: InitBusinessAccountIdentityMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      }
    ],
    "concreteType": "BusinessAccountIdentityStatus",
    "kind": "LinkedField",
    "name": "initBusinessAccountIdentity",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BusinessAccountIdentity",
        "kind": "LinkedField",
        "name": "data",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accountID",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InitBusinessAccountIdentityMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InitBusinessAccountIdentityMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "460f8d6a9197d5c717a7e02554793199",
    "id": null,
    "metadata": {},
    "name": "InitBusinessAccountIdentityMutation",
    "operationKind": "mutation",
    "text": "mutation InitBusinessAccountIdentityMutation(\n  $email: String!\n) {\n  initBusinessAccountIdentity(email: $email) {\n    data {\n      id\n      accountID\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1e02aa07ea5ac2d520a5ebe486cdfc8e";

export default node;
