import { Badge } from 'antd'
import { TBusinessUserPii } from 'src/graphql'
import { EKybStatus, EKybStatusBadge, EKycStatus, EKycStatusTxt } from 'src/interfaces'

export class KybUtils {
  static canStartReview(user?: TBusinessUserPii | null) {
    return ['REVIEW_IN_PROGRESS'].includes(user?.currentKyb?.state as string)
  }

  static canOnHold(user?: TBusinessUserPii | null) {
    return true
  }

  static canApprove(user?: TBusinessUserPii | null) {
    return true
  }

  static canDeny(user?: TBusinessUserPii | null) {
    return true
  }

  static renderKybStatusBadge = (status?: string) => {
    const badge = EKybStatusBadge[status as EKybStatus] || 'default'
    const statusTxt = EKycStatusTxt[status as EKycStatus] || status
    return <Badge status={badge} className="wp-no-wrap" text={statusTxt}/>
  }
}
