import { graphql } from 'relay-runtime'

import { AdminUpdateBusinessProfileMutation } from './__generated__/AdminUpdateBusinessProfileMutation.graphql'

export type { AdminUpdateBusinessProfileMutation }

export const adminUpdateBusinessProfileMutation = graphql`
  mutation AdminUpdateBusinessProfileMutation($input: SubmitBusinessProfileInput!) {
    adminUpdateBusinessProfile(input: $input) {
      data {
        ...on BusinessAccountIdentity {
          id
        }
      }
    }
  }
`
