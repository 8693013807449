import { graphql } from 'relay-runtime'

import { BdRewardsQuery } from './__generated__/BdRewardsQuery.graphql'

export type { BdRewardsQuery }

export type TBdReward = DeepWriteable<DeepExclude<TArrayItem<BdRewardsQuery['response']['bdRewards']['edges']>, null>['node']>

export const bdRewardsQuery = graphql`
  query BdRewardsQuery(
    $after: Cursor
    $first: Int
    $before: Cursor
    $last: Int
    $where: BDRewardWhereInput
  ) {
    bdRewards(
      after: $after
      first: $first
      before: $before
      last: $last
      where: $where
    ) {
        ...on BDRewardConnection {
          totalCount
          pageInfo {
            ...on PageInfo {
              hasNextPage
              hasPreviousPage
              startCursor
              endCursor
            }
          }
          edges {
            ...on BDRewardEdge {
              cursor
              node {
                ...on BDReward {
                  id
                  createTime
                  updateTime
                  bdID
                  epochID
                  earningAmount
                  clientsEarningAmount
                  metadata
                  bd {
                    ...on AdminPortalUser {
                      id
                      firstName
                      lastName
                      email
                    }
                  }
                  epoch {
                    ...on KOLRewardEpoch { id }
                  }
                }
              }
            }
          }
        }
    }
  }
`
