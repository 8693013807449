import { graphql } from 'relay-runtime'

import { InitBusinessAccountIdentityMutation } from './__generated__/InitBusinessAccountIdentityMutation.graphql'

export type { InitBusinessAccountIdentityMutation }

export const initBusinessAccountIdentityMutation = graphql`
  mutation InitBusinessAccountIdentityMutation($email: String!) {
    initBusinessAccountIdentity(email: $email) {
      data {
        ...on BusinessAccountIdentity {
          id
          accountID
        }
      }
    }
  }
`
