import { DownloadOutlined } from '@ant-design/icons'
import { Button, Input, Select, Table } from 'antd'
import { FC, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { KolTrackerApi } from 'src/api'
import { TKolStatisticsStatus } from 'src/graphql'
import { useBehaviorMapper, useUnsubscribe } from 'src/hooks'
import { EPaths, ERoutes, generate } from 'src/router'
import { BreadcrumbService, MessageService } from 'src/services'
import { KOLEpochsStore } from 'src/store'
import { renderColumns } from './columns'

export const BDTrackerKolTracker: FC = () => {
  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = [{
      route: EPaths.BD_KOL_TRACKER,
      label: 'KOL Tracker'
    }]
    return () => {
      BreadcrumbService.items = []
    }
  }, [])

  const navigate = useNavigate()
  const unsubscribe$ = useUnsubscribe()

  const kolEpochsOptions = useBehaviorMapper(KOLEpochsStore.kolEpochsOptions$)
  useEffect(() => {
    KOLEpochsStore.fetchKolEpochs()
  }, [])

  const [loading, setLoading] = useState(false)
  const [kolStatistics, setKolStatistics] = useState<TKolStatisticsStatus>({})
  const [currentPage, setCurrentPage] = useState(1)
  const [keyword, setKeyword] = useState('')
  const [filter, setFilter] = useState<Pick<Parameters<typeof KolTrackerApi.kolStatistics>[0]['input'], 'query' | 'epochID'>>({ epochID: '' })

  const fetch = useCallback((input: Omit<Parameters<typeof KolTrackerApi.kolStatistics>[0]['input'], 'epochID'>) => {
    if (!filter.epochID) {
      return
    }

    setLoading(true)
    from(KolTrackerApi.kolStatistics({ input: { ...input, ...filter } }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          MessageService.error(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe((kolStatistics) => {
        setKolStatistics(kolStatistics)
      })
  }, [filter, unsubscribe$])

  const handleExportCSV = useCallback((data: Blob) => {
    try {
      const url = window.URL.createObjectURL(data)

      const a = document.createElement('a')
      a.href = url
      a.download = 'kol.csv'

      document.body.appendChild(a)
      a.click()

      window.URL.revokeObjectURL(url)
      document.body.removeChild(a)
    } catch (error) {
      MessageService.error('Something went wrong!')
    }
  }, [])

  const onClickExport = useCallback(() => {
    from(KolTrackerApi.kol())
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          MessageService.error(error)
          return EMPTY
        })
      )
      .subscribe((res) => {
        handleExportCSV(res?.data)
      })
  }, [handleExportCSV, unsubscribe$])

  useEffect(() => {
    setCurrentPage(1)
  }, [filter])

  useEffect(() => {
    fetch({
      pageNumber: currentPage,
      pageSize: 10
    })
  }, [currentPage, fetch])

  useEffect(() => {
    if (!filter.epochID && kolEpochsOptions?.[0]?.value) {
      setFilter((prev) => ({
        ...prev,
        epochID: kolEpochsOptions?.[0]?.value
      }))
    }
  }, [filter.epochID, kolEpochsOptions])

  return (
    <section className="fx fx-column fx-extend gap-2">
      <div className="fx fx-jc-space-between">
        <div className="fx fx-extend fx-ai-center gap-2 label-1">
          KOL tracker
          <Select
            allowClear
            style={{ flex: '0 0 100px' }}
            placeholder="Epoch #"
            value={filter.epochID}
            options={kolEpochsOptions}
            onChange={(value) => setFilter((prev) => ({
              ...prev,
              epochID: value || ''
            }))}
          />
        </div>

        <div className="fx fx-ai-center fx-jc-end gap-2">
          <Input.Search
            allowClear
            placeholder="Search by email"
            style={{ flex: '0 0 200px' }}
            readOnly={loading}
            value={keyword}
            onChange={(e) => setKeyword(e.target.value.trim().toLowerCase())}
            onSearch={() => setFilter((prev) => ({
              ...prev,
              query: keyword
            }))}
          />
          <Button
            type="default"
            onClick={() => navigate(generate(ERoutes.BD_KOL_POINT_DISTRIBUTION))}
          >
            Point Distributions
          </Button>
          <Button
            type="primary"
            onClick={() => navigate(generate(EPaths.BD_KOL_CONFIGURATIONS_HISTORY))}
          >
            See history
          </Button>
          <Button onClick={onClickExport} type="primary">
            <DownloadOutlined/>
            Export
          </Button>
        </div>
      </div>

      <Table
        bordered
        rowKey="accountID"
        className="fx-extend"
        loading={loading}
        dataSource={kolStatistics?.data || []}
        columns={renderColumns({
          onFresh: () => fetch({
            pageNumber: currentPage,
            pageSize: 10
          })
        })}
        tableLayout="fixed"
        pagination={{
          defaultPageSize: 10,
          total: kolStatistics.total,
          onChange: (page) => setCurrentPage(page)
        }}
        scroll={{ x: true }}
      />
    </section>
  )
}
