
export enum EAccountPermission {
  ACC_OFF_CHAIN_OPS = 'ACC_OFF_CHAIN_OPS',
  ACC_OFF_CHAIN_USER_MANAGEMENT = 'ACC_OFF_CHAIN_USER_MANAGEMENT',
  ACC_OFF_CHAIN_ADMIN = 'ACC_OFF_CHAIN_ADMIN',
  ACC_OFF_CHAIN_USER = 'ACC_OFF_CHAIN_USER'
}

export const EAccountPermissionTxt = Object.freeze({
  [EAccountPermission.ACC_OFF_CHAIN_OPS]: 'OPS',
  [EAccountPermission.ACC_OFF_CHAIN_USER_MANAGEMENT]: 'User management',
  [EAccountPermission.ACC_OFF_CHAIN_ADMIN]: 'Admin',
  [EAccountPermission.ACC_OFF_CHAIN_USER]: 'User'
})

export const getAccountPermissionTxt = (status?: EAccountPermission | string) => {
  return typeof status === 'string' ? EAccountPermissionTxt[status as EAccountPermission] || status : ''
}
