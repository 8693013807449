/**
 * @generated SignedSource<<76e3180aecc97c4c59c8890242a64cc0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type WalletKYAKyaProvider = "elliptic" | "%future added value";
export type WalletKYAResult = "clean" | "dirty" | "not_available" | "%future added value";
export type ExcludedWalletWhereInput = {
  and?: ReadonlyArray<ExcludedWalletWhereInput> | null;
  createTime?: any | null;
  createTimeGT?: any | null;
  createTimeGTE?: any | null;
  createTimeIn?: ReadonlyArray<any> | null;
  createTimeIsNil?: boolean | null;
  createTimeLT?: any | null;
  createTimeLTE?: any | null;
  createTimeNEQ?: any | null;
  createTimeNotIn?: ReadonlyArray<any> | null;
  createTimeNotNil?: boolean | null;
  deleteTime?: any | null;
  deleteTimeGT?: any | null;
  deleteTimeGTE?: any | null;
  deleteTimeIn?: ReadonlyArray<any> | null;
  deleteTimeIsNil?: boolean | null;
  deleteTimeLT?: any | null;
  deleteTimeLTE?: any | null;
  deleteTimeNEQ?: any | null;
  deleteTimeNotIn?: ReadonlyArray<any> | null;
  deleteTimeNotNil?: boolean | null;
  hasWalletKya?: boolean | null;
  hasWalletKyaWith?: ReadonlyArray<WalletKYAWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  latestUpdatingAdminUser?: string | null;
  latestUpdatingAdminUserContains?: string | null;
  latestUpdatingAdminUserContainsFold?: string | null;
  latestUpdatingAdminUserEqualFold?: string | null;
  latestUpdatingAdminUserGT?: string | null;
  latestUpdatingAdminUserGTE?: string | null;
  latestUpdatingAdminUserHasPrefix?: string | null;
  latestUpdatingAdminUserHasSuffix?: string | null;
  latestUpdatingAdminUserIn?: ReadonlyArray<string> | null;
  latestUpdatingAdminUserIsNil?: boolean | null;
  latestUpdatingAdminUserLT?: string | null;
  latestUpdatingAdminUserLTE?: string | null;
  latestUpdatingAdminUserNEQ?: string | null;
  latestUpdatingAdminUserNotIn?: ReadonlyArray<string> | null;
  latestUpdatingAdminUserNotNil?: boolean | null;
  latestWalletKyaID?: string | null;
  latestWalletKyaIDContains?: string | null;
  latestWalletKyaIDContainsFold?: string | null;
  latestWalletKyaIDEqualFold?: string | null;
  latestWalletKyaIDGT?: string | null;
  latestWalletKyaIDGTE?: string | null;
  latestWalletKyaIDHasPrefix?: string | null;
  latestWalletKyaIDHasSuffix?: string | null;
  latestWalletKyaIDIn?: ReadonlyArray<string> | null;
  latestWalletKyaIDIsNil?: boolean | null;
  latestWalletKyaIDLT?: string | null;
  latestWalletKyaIDLTE?: string | null;
  latestWalletKyaIDNEQ?: string | null;
  latestWalletKyaIDNotIn?: ReadonlyArray<string> | null;
  latestWalletKyaIDNotNil?: boolean | null;
  not?: ExcludedWalletWhereInput | null;
  or?: ReadonlyArray<ExcludedWalletWhereInput> | null;
  updateTime?: any | null;
  updateTimeGT?: any | null;
  updateTimeGTE?: any | null;
  updateTimeIn?: ReadonlyArray<any> | null;
  updateTimeIsNil?: boolean | null;
  updateTimeLT?: any | null;
  updateTimeLTE?: any | null;
  updateTimeNEQ?: any | null;
  updateTimeNotIn?: ReadonlyArray<any> | null;
  updateTimeNotNil?: boolean | null;
  walletAddress?: string | null;
  walletAddressContains?: string | null;
  walletAddressContainsFold?: string | null;
  walletAddressEqualFold?: string | null;
  walletAddressGT?: string | null;
  walletAddressGTE?: string | null;
  walletAddressHasPrefix?: string | null;
  walletAddressHasSuffix?: string | null;
  walletAddressIn?: ReadonlyArray<string> | null;
  walletAddressLT?: string | null;
  walletAddressLTE?: string | null;
  walletAddressNEQ?: string | null;
  walletAddressNotIn?: ReadonlyArray<string> | null;
};
export type WalletKYAWhereInput = {
  and?: ReadonlyArray<WalletKYAWhereInput> | null;
  createTime?: any | null;
  createTimeGT?: any | null;
  createTimeGTE?: any | null;
  createTimeIn?: ReadonlyArray<any> | null;
  createTimeIsNil?: boolean | null;
  createTimeLT?: any | null;
  createTimeLTE?: any | null;
  createTimeNEQ?: any | null;
  createTimeNotIn?: ReadonlyArray<any> | null;
  createTimeNotNil?: boolean | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  kyaProvider?: WalletKYAKyaProvider | null;
  kyaProviderIn?: ReadonlyArray<WalletKYAKyaProvider> | null;
  kyaProviderNEQ?: WalletKYAKyaProvider | null;
  kyaProviderNotIn?: ReadonlyArray<WalletKYAKyaProvider> | null;
  kyaProviderResponse?: string | null;
  kyaProviderResponseContains?: string | null;
  kyaProviderResponseContainsFold?: string | null;
  kyaProviderResponseEqualFold?: string | null;
  kyaProviderResponseGT?: string | null;
  kyaProviderResponseGTE?: string | null;
  kyaProviderResponseHasPrefix?: string | null;
  kyaProviderResponseHasSuffix?: string | null;
  kyaProviderResponseIn?: ReadonlyArray<string> | null;
  kyaProviderResponseIsNil?: boolean | null;
  kyaProviderResponseLT?: string | null;
  kyaProviderResponseLTE?: string | null;
  kyaProviderResponseNEQ?: string | null;
  kyaProviderResponseNotIn?: ReadonlyArray<string> | null;
  kyaProviderResponseNotNil?: boolean | null;
  not?: WalletKYAWhereInput | null;
  or?: ReadonlyArray<WalletKYAWhereInput> | null;
  result?: WalletKYAResult | null;
  resultIn?: ReadonlyArray<WalletKYAResult> | null;
  resultNEQ?: WalletKYAResult | null;
  resultNotIn?: ReadonlyArray<WalletKYAResult> | null;
  riskScore?: number | null;
  riskScoreGT?: number | null;
  riskScoreGTE?: number | null;
  riskScoreIn?: ReadonlyArray<number> | null;
  riskScoreIsNil?: boolean | null;
  riskScoreLT?: number | null;
  riskScoreLTE?: number | null;
  riskScoreNEQ?: number | null;
  riskScoreNotIn?: ReadonlyArray<number> | null;
  riskScoreNotNil?: boolean | null;
  updateTime?: any | null;
  updateTimeGT?: any | null;
  updateTimeGTE?: any | null;
  updateTimeIn?: ReadonlyArray<any> | null;
  updateTimeIsNil?: boolean | null;
  updateTimeLT?: any | null;
  updateTimeLTE?: any | null;
  updateTimeNEQ?: any | null;
  updateTimeNotIn?: ReadonlyArray<any> | null;
  updateTimeNotNil?: boolean | null;
  walletAddress?: string | null;
  walletAddressContains?: string | null;
  walletAddressContainsFold?: string | null;
  walletAddressEqualFold?: string | null;
  walletAddressGT?: string | null;
  walletAddressGTE?: string | null;
  walletAddressHasPrefix?: string | null;
  walletAddressHasSuffix?: string | null;
  walletAddressIn?: ReadonlyArray<string> | null;
  walletAddressLT?: string | null;
  walletAddressLTE?: string | null;
  walletAddressNEQ?: string | null;
  walletAddressNotIn?: ReadonlyArray<string> | null;
};
export type ExcludedWalletsQuery$variables = {
  after?: any | null;
  before?: any | null;
  first?: number | null;
  last?: number | null;
  where?: ExcludedWalletWhereInput | null;
};
export type ExcludedWalletsQuery$data = {
  readonly excludedWallets: {
    readonly edges: ReadonlyArray<{
      readonly cursor: any;
      readonly node: {
        readonly id: string;
        readonly isExcluded: boolean | null;
        readonly walletAddress: string;
      } | null;
    } | null> | null;
    readonly pageInfo: {
      readonly endCursor: any | null;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
      readonly startCursor: any | null;
    };
    readonly totalCount: number;
  };
};
export type ExcludedWalletsQuery = {
  response: ExcludedWalletsQuery$data;
  variables: ExcludedWalletsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "before"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "where"
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "after",
        "variableName": "after"
      },
      {
        "kind": "Variable",
        "name": "before",
        "variableName": "before"
      },
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "last",
        "variableName": "last"
      },
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where"
      }
    ],
    "concreteType": "ExcludedWalletConnection",
    "kind": "LinkedField",
    "name": "excludedWallets",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "PageInfo",
        "kind": "LinkedField",
        "name": "pageInfo",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasNextPage",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasPreviousPage",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startCursor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endCursor",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ExcludedWalletEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cursor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ExcludedWallet",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isExcluded",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "walletAddress",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ExcludedWalletsQuery",
    "selections": (v5/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "ExcludedWalletsQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "253a57366699b8ef6c454d94e8de74e2",
    "id": null,
    "metadata": {},
    "name": "ExcludedWalletsQuery",
    "operationKind": "query",
    "text": "query ExcludedWalletsQuery(\n  $after: Cursor\n  $first: Int\n  $before: Cursor\n  $last: Int\n  $where: ExcludedWalletWhereInput\n) {\n  excludedWallets(after: $after, first: $first, before: $before, last: $last, where: $where) {\n    totalCount\n    pageInfo {\n      hasNextPage\n      hasPreviousPage\n      startCursor\n      endCursor\n    }\n    edges {\n      cursor\n      node {\n        id\n        isExcluded\n        walletAddress\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "dbbc96100ceee6e5bcce34cb70dbc741";

export default node;
