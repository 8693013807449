import { graphql } from 'relay-runtime'

import { GetUserIdentityQuery } from './__generated__/GetUserIdentityQuery.graphql'

export type { GetUserIdentityQuery }

export type TUserPii = Partial<DeepWriteable<GetUserIdentityQuery['response']['getUserIdentity']['data']>>

export const getUserIdentityQuery = graphql`
  query GetUserIdentityQuery($input: GetUserIdentityInput!) {
    getUserIdentity(input: $input) {
      data {
        ...on UserIdentity {
          id
          createTime
          updateTime
          userID
          accountID
          email
          nationality
          firstName
          lastName
          canRestartKYC
          dateOfBirth
          documentNumber
          documentType
          documentIssueCountry
          kycAttemptID
          kycReviewState
          # businessAccountIdentity {
          #   ...on BusinessAccountIdentity {}
          # }
          # contactPersonBusinessAcountIdentity {
          #   ...on BusinessAccountIdentity {}
          # }
          complyCubeClientID
          currentKyc {
            ...on KYCAttempt {
              id
              createTime
              updateTime
              userIdentityID
              state
              countryOfResidence
              userType
              location
              riskScore {
                ...on KYCRiskScore {
                  id
                  score
                  level
                  version
                }
              }
              address {
                ...on Address {
                  id
                  createTime
                  updateTime
                  ccAddressID
                  addressLineOne
                  addressLineTwo
                  city
                  postalCode
                  stateProvince
                }
              }
              questionAnswers {
                ...on QuestionAnswer {
                  id
                  createTime
                  updateTime
                  questionID
                  kycAttemptID
                  optionID
                  option {
                    ...on QuestionAnswerOption {
                      id
                      display
                    }
                  }
                  question {
                    ...on Question {
                      id
                      display
                      type
                    }
                  }
                }
              }
              kycTags {
                ...on KYCTag{
                  id
                  value
                }
              }
              kycReviewDocuments {
                ...on KYCReviewDocument {
                  id
                  createTime
                  updateTime
                  uploaderUserID
                  url
                  type
                  comment
                }
              }
              kycActionLogs {
                ...on KYCActionLog {
                  id
                  createTime
                  updateTime
                  kycAttemptID
                  subjectUserID
                  subjectEmail
                  subjectType
                  targetUserID
                  action
                  prevAttemptState
                  currentAttemptState
                  prevReviewState
                  currentReviewState
                  message
                  data
                }
              }
              complyCubeChecks {
                ...on ComplyCubeCheck {
                  id
                  ccCheckID
                  ccType
                  ccDocumentID
                  ccLivePhotoID
                  ccStatus
                  ccOutcome
                }
              }
              extractedInfoID
              extractedInfo {
                ...on KYCExtractedInfo {
                  id
                  createTime
                  updateTime
                  ccCheckID
                  kycAttemptID
                  firstName
                  lastName
                  dateOfBirth
                  nationality
                  documentNumber
                  documentType
                  expirationDate
                  issueDate
                  issueCountry
                  issueAuthority
                }
              }
            }
          }
          kycAttempts {
            ...on KYCAttempt {
              id
              createTime
              updateTime
              userIdentityID
              state
              countryOfResidence
              userType
              location
              kycTags {
                ...on KYCTag{
                  id
                  value
                }
              }
              extractedInfoID
              extractedInfo {
                ...on KYCExtractedInfo {
                  id
                  createTime
                  updateTime
                  ccCheckID
                  kycAttemptID
                  firstName
                  lastName
                  dateOfBirth
                  nationality
                  documentNumber
                  documentType
                }
              }
            }
          }
        }
      }
    }
  }
`
