export class JsonUtils {
  static parse <T = any>(json?: string | null, _default?: T): T {
    try {
      return json
        ? JSON.parse(json) as T
        : _default as T
    } catch (e) {
      return _default as T
    }
  }
}
