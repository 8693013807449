import { graphql } from 'relay-runtime'

import { KolRewardsQuery } from './__generated__/KolRewardsQuery.graphql'

export type { KolRewardsQuery }

export type TKolReward = DeepWriteable<DeepExclude<TArrayItem<KolRewardsQuery['response']['kolRewards']['edges']>, null>['node']>

export const kolRewardsQuery = graphql`
  query KolRewardsQuery(
    $after: Cursor
    $first: Int
    $before: Cursor
    $last: Int
    $where: KOLRewardWhereInput
  ) {
    kolRewards(
      after: $after
      first: $first
      before: $before
      last: $last
      where: $where
    ) {
        ...on KOLRewardConnection {
          totalCount
          pageInfo {
            ...on PageInfo {
              hasNextPage
              hasPreviousPage
              startCursor
              endCursor
            }
          }
          edges {
            ...on KOLRewardEdge {
              cursor
              node {
                ...on KOLReward {
                  id
                  createTime
                  updateTime
                  accountID
                  epochID
                  earningAmount
                  dueAmount
                  paidAmount
                  tradingVolume
                  directTradingVolume
                  indirectTradingVolume
                  directFee
                  indirectFee
                  fee
                  metadata
                  comment
                  account {
                    ...on Account {
                      id
                      isBusiness
                      name
                      displayName
                      kolConfigs {
                        ...on KOLConfiguration {
                          id
                          createTime
                          updateTime
                          accountID
                          type
                          directCommissionRate
                          indirectCommissionRate
                          commissionRate
                          commissionTier
                          # account { ...on Account { id } }
                          bdsInCharge {
                            ...on AdminPortalUser {
                              id
                              firstName
                              lastName
                              email
                            }
                          }
                        }
                      }
                      # kolRewards {
                      #   ...on KOLReward {
                      #     id
                      #   }
                      # }
                    }
                  }
                  epoch {
                    ...on KOLRewardEpoch { id }
                  }
                }
              }
            }
          }
        }
    }
  }
`
