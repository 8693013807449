import { EPaths, IRouterOptions } from 'src/router'
import { AuthConfirm } from './components/confirm'
import { Login } from './components/login'
import { OAuth2Callback } from './components/oauth2/callback'
import { Register } from './components/register'

export enum ERoutes {
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  AUTH_CONFIRM = 'AUTH_CONFIRM',
  OAUTH_CALLBACK = 'OAUTH_CALLBACK'
}

export const routes: IRouterOptions = [
  {
    path: EPaths.LOGIN,
    name: ERoutes.LOGIN,
    component: Login,
    meta: {
      requiredAuth: false
    }
  },
  {
    path: EPaths.REGISTER,
    name: ERoutes.REGISTER,
    component: Register,
    meta: {
      requiredAuth: false
    }
  },
  {
    path: EPaths.AUTH_CONFIRM,
    name: ERoutes.AUTH_CONFIRM,
    component: AuthConfirm,
    meta: {
      requiredAuth: false
    }
  },
  {
    path: EPaths.OAUTH_CALLBACK,
    name: ERoutes.OAUTH_CALLBACK,
    component: OAuth2Callback,
    meta: {
      requiredAuth: false
    }
  }
]

export const AuthModule = {
  ERoutes,
  routes
}
