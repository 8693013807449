import { graphql } from 'relay-runtime'

import { KolRewardEpochesQuery } from './__generated__/KolRewardEpochesQuery.graphql'

export type { KolRewardEpochesQuery }

export type TKolRewardEpoch = DeepWriteable<DeepExclude<TArrayItem<KolRewardEpochesQuery['response']['kolRewardEpoches']['edges']>, null>['node']>

export const kolRewardEpochesQuery = graphql`
  query KolRewardEpochesQuery(
    $after: Cursor
    $first: Int
    $before: Cursor
    $last: Int
    $where: KOLRewardEpochWhereInput
  ) {
    kolRewardEpoches(
      after: $after
      first: $first
      before: $before
      last: $last
      where: $where
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...on KOLRewardEpoch {
            id
            createTime
            updateTime
            epoch
            status
            institutionStatus
            startTime
            endTime
          }
        }
      }
    }
  }
`
