import { ArrowLeftOutlined } from '@ant-design/icons'
import { FC, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { EPaths } from 'src/router'
import { BreadcrumbService } from 'src/services'
import { BdActionLogs } from '../bd-action-logs'

export const KOLConfigurationsHistory: FC = () => {
  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = [
      {
        route: EPaths.BD_KOL_TRACKER,
        label: 'KOL Tracker'
      },
      {
        route: EPaths.BD_KOL_CONFIGURATIONS_HISTORY,
        label: 'KOL Configurations History'
      }
    ]
    return () => {
      BreadcrumbService.items = []
    }
  }, [])

  return (
    <BdActionLogs
      header={(
        <Link to={EPaths.BD_KOL_TRACKER} className="fx fx-ai-center gap-3 black">
          <ArrowLeftOutlined/>
          <span className="label-1">KOL Configurations History</span>
        </Link>
      )}
    />
  )
}
