import { EPaths, IRouterOptions } from 'src/router'
import { CountryList, IndividualKycList } from './components'
import { BusinessKybList } from './components/business-kyb'
import { BusinessKybDetail } from './components/business-kyb/business-kyb-detail'
import { BusinessKycList } from './components/business-kyc'
import { BusinessKycDetail } from './components/business-kyc/business-kyc-detail'
import { CountryConfigHistory } from './components/country-configurations/history'
import { ExcludedWallets } from './components/excluded-wallets'
import { IndividualKycDetail } from './components/individual-kyc/individual-kyc-detail'

export enum ERoutes {
  COUNTRY_CONFIGURATIONS = 'COUNTRY_CONFIGURATIONS',
  COUNTRY_CONFIG_HISTORY = 'COUNTRY_CONFIG_HISTORY',
  INDIVIDUAL_KYC = 'INDIVIDUAL_KYC',
  INDIVIDUAL_KYC_DETAIL = 'INDIVIDUAL_KYC_DETAIL',
  BUSINESS_KYC = 'BUSINESS_KYC',
  BUSINESS_KYC_DETAIL = 'BUSINESS_KYC_DETAIL',
  BUSINESS_KYB = 'BUSINESS_KYB',
  BUSINESS_KYB_DETAIL = 'BUSINESS_KYB_DETAIL',
  EXCLUDED_WALLETS = 'EXCLUDED_WALLETS'
}

export const routes: IRouterOptions = [
  {
    path: EPaths.COUNTRY_CONFIGURATIONS,
    name: ERoutes.COUNTRY_CONFIGURATIONS,
    component: CountryList,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: EPaths.COUNTRY_CONFIG_HISTORY,
    name: ERoutes.COUNTRY_CONFIG_HISTORY,
    component: CountryConfigHistory,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: EPaths.INDIVIDUAL_KYC,
    name: ERoutes.INDIVIDUAL_KYC,
    component: IndividualKycList,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: EPaths.INDIVIDUAL_KYC_DETAIL,
    name: ERoutes.INDIVIDUAL_KYC_DETAIL,
    component: IndividualKycDetail,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: EPaths.BUSINESS_KYC,
    name: ERoutes.BUSINESS_KYC,
    component: BusinessKycList,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: EPaths.BUSINESS_KYC_DETAIL,
    name: ERoutes.BUSINESS_KYC_DETAIL,
    component: BusinessKycDetail,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: EPaths.BUSINESS_KYB,
    name: ERoutes.BUSINESS_KYB,
    component: BusinessKybList,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: EPaths.BUSINESS_KYB_DETAIL,
    name: ERoutes.BUSINESS_KYB_DETAIL,
    component: BusinessKybDetail,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: EPaths.EXCLUDED_WALLETS,
    name: ERoutes.EXCLUDED_WALLETS,
    component: ExcludedWallets,
    meta: {
      requiredAuth: true
    }
  }
]

export const ComplianceModule = {
  ERoutes,
  routes
}
