export enum EPaths {
  COUNTRY_CONFIGURATIONS = '/compliance/country-configurations',
  COUNTRY_CONFIG_HISTORY = '/compliance/country-configurations/history',
  INDIVIDUAL_KYC = '/compliance/individual-kyc',
  INDIVIDUAL_KYC_DETAIL = '/compliance/individual-kyc/:accountID/:userID',
  BUSINESS_KYC = '/compliance/business-kyc',
  BUSINESS_KYC_DETAIL = '/compliance/business-kyc/:accountID/:userID',
  BUSINESS_KYB = '/compliance/business-kyb',
  BUSINESS_KYB_DETAIL = '/compliance/business-kyb/:accountID',
  EXCLUDED_WALLETS = '/compliance/excluded-wallets'
}
