import { Button, Input, Modal } from 'antd'
import { ComponentProps, FC, SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { PiiApi } from 'src/api'
import { ErrorMessage } from 'src/atoms'
import { useBehaviorMapper, useDidMountEffect, useUnsubscribe, useValidation } from 'src/hooks'
import { AuthService, MessageService } from 'src/services'
import { EventUtils } from 'src/utils'
import { object, string } from 'yup'

const schema = object().shape({
  email: string().typeError('Email invalid').email('Email invalid').required('Email is required')
})

export const DialogKYCManualUpload: FC<Omit<
  ComponentProps<typeof Modal>,
  'onOk' | 'afterClose'
> & {
  btnProps?: ComponentProps<typeof Button>
  afterClose?: (id?: string) => any
  isBusiness: boolean
}> = ({
  btnProps,
  isBusiness,
  ...props
}) => {
  const unsubscribe$ = useUnsubscribe()
  const user = useBehaviorMapper(AuthService.user$)
  const [loading, setLoading] = useState(false)
  const [invisible, setInvisible] = useState<boolean | string>(true)
  const [form, setForm] = useState<Parameters<typeof PiiApi.initUserIdentity>[0]['input']>({
    email: '',
    isBusiness
  })

  const { validate, errors, reset } = useValidation({
    data: form,
    schema
  })

  useEffect(() => {
    reset()
    setForm({ email: '', isBusiness })
  }, [invisible, user, isBusiness, reset])

  useEffect(() => {
    setInvisible(!props.open)
  }, [props.open])

  useDidMountEffect(() => {
    if (invisible) {
      props.afterClose?.(
        typeof invisible === 'boolean'
          ? undefined
          : invisible
      )
    }
  }, [invisible])

  const submit = useCallback(async (e: SyntheticEvent) => {
    EventUtils.preventDefault(e)

    const { isValid } = await validate()

    if (!isValid) {
      return
    }

    const promise = PiiApi.initUserIdentity({ input: form })
    setLoading(true)
    from(promise)
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          MessageService.error(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(({ id }) => {
        MessageService.info('User manual upload success')
        setInvisible(id)
      })
  }, [form, unsubscribe$, validate])

  return (
    <>
      <Button
        type="primary"
        {...btnProps}
        onClick={() => setInvisible(false)}
      >
        {props.children || 'User Manual Upload'}
      </Button>

      <Modal
        title={props.title || 'User Manual Upload'}
        open={!invisible}
        centered
        closable={!loading} // display X icon
        keyboard={false} // disable close on press ESC
        maskClosable={false} // disable close on click outside
        okButtonProps={{ disabled: loading }}
        cancelButtonProps={{ disabled: loading }}
        okText="Ok"
        cancelText="Cancel"
        onOk={submit}
        onCancel={() => setInvisible(true)}
      >
        {!invisible && (
          <form onSubmit={submit} className="fx fx-column fx-extend gap-2">
            <input className="d-none" type="submit"/>

            <Input
              required
              type="email"
              name="email"
              placeholder="Email"
              readOnly={loading}
              value={form.email}
              status={errors.hasError('email') ? 'error' : undefined}
              onChange={(e) => setForm(prev => ({ ...prev, email: e.target.value }))}
            />
            <ErrorMessage>{errors.getError('email')}</ErrorMessage>
          </form>
        )}
      </Modal>
    </>
  )
}
