import { CopyOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { ExcludedWalletEdge } from 'src/graphql'
import { ClipboardService } from 'src/services'

interface Actions {
  onEdit: (record: ExcludedWalletEdge) => void
}

export const renderColumns = ({ onEdit }: Actions) => [
  {
    title: 'No',
    dataIndex: 'code',
    key: 'code',
    render: (_: any, record: any, index: number) => index + 1
  },
  {
    title: 'Address',
    dataIndex: 'walletAddress',
    key: 'walletAddress',
    render: (walletAddress: string) => (
      <div className="fx fx-ai-center gap-1">
        {walletAddress}
        <CopyOutlined
          className="pointer"
          onClick={() => ClipboardService.copy(walletAddress)}
        />
      </div>
    )
  },
  {
    title: 'Excluded',
    dataIndex: 'isExcluded',
    key: 'isExcluded',
    render: (excluded: boolean, record: ExcludedWalletEdge) => <div>{excluded ? 'True' : 'False'} <Button type="link" onClick={() => onEdit(record)}>Edit</Button></div>
  }
]
