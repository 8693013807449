import { AppstoreOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons'
import { ComponentType } from 'react'
import { matchPath } from 'react-router'
import { ERoutes, generate } from 'src/router'

// export const menus: IMenuItem[] = Array.from(
//   new Array(5),
//   (val, i) => ({
//     key: `menuItem${i + 1}`,
//     label: `Menu item ${i + 1}`,
//     pathname: `/menu-url-${i + 1}`,
//     subMenus: Array.from(
//       new Array(10),
//       (val, j) => ({
//         key: `menuItemSub${i * 10 + j + 1}`,
//         label: `Sub menu item ${i * 10 + j + 1}`,
//         pathname: `/menu-url-${i * 10 + j + 1}`
//       })
//     )
//   })
// )

export interface IMenuItem {
  key: string
  label: string
  pathname?: string
  icon?: ComponentType
  subMenus?: IMenuItem[]
  superAdmin?: boolean
}

export const menus: IMenuItem[] = [
  {
    key: ERoutes.ADMIN_MANAGEMENT,
    label: 'Admin Portal',
    icon: UserOutlined,
    pathname: generate(ERoutes.ADMIN_MANAGEMENT)
  },
  {
    key: 'business-development',
    label: 'Business Development',
    icon: AppstoreOutlined,
    subMenus: [
      {
        key: 'BD_KOLTracker',
        label: 'KOL Tracker',
        pathname: generate(ERoutes.BD_KOL_TRACKER)
      },
      {
        key: 'Inst_Tracker',
        label: 'Inst. Tracker',
        pathname: generate(ERoutes.BD_INSTITUTION_TRACKER)
      },
      {
        key: 'BD_Performance',
        label: 'BD Performance',
        pathname: generate(ERoutes.BD_PERFORMANCE)
      }
    ]
  },
  {
    key: 'compliance',
    label: 'Compliance',
    icon: TeamOutlined,
    subMenus: [
      {
        key: 'Wallet Configurations',
        label: 'Wallet Configurations',
        pathname: generate(ERoutes.EXCLUDED_WALLETS)
      },
      {
        key: 'Country Configurations',
        label: 'Country Configurations',
        pathname: generate(ERoutes.COUNTRY_CONFIGURATIONS)
      },
      {
        key: 'Individual-kyc',
        label: 'Individual KYC',
        pathname: generate(ERoutes.INDIVIDUAL_KYC)
      },
      {
        key: 'Business-kyc',
        label: 'Business KYC',
        pathname: generate(ERoutes.BUSINESS_KYC)
      },
      {
        key: 'Business-kyb',
        label: 'Business KYB',
        pathname: generate(ERoutes.BUSINESS_KYB)
      }
    ]
  },
  {
    key: 'CSR',
    label: 'CSR',
    icon: TeamOutlined,
    subMenus: [
      {
        key: 'Individual',
        label: 'Individual',
        pathname: generate(ERoutes.CSR_INDIVIDUAL)
      },
      {
        key: 'Business',
        label: 'Business',
        pathname: generate(ERoutes.CSR_BUSINESS)
      }
    ]
  }
]

export const getOpenKeys = (pathname: string) => {
  const openKeys: string[] = []

  menus.forEach((menu) => {
    if (menu.subMenus) {
      menu.subMenus.forEach((subMenu) => {
        if (subMenu.pathname && matchPath(pathname, subMenu.pathname)) {
          openKeys.push(menu.key)
        }
      })
    } else if (menu.pathname && matchPath(pathname, menu.pathname)) {
      openKeys.push(menu.key)
    }
  })

  return openKeys
}
