/**
 * @generated SignedSource<<268bd705022f9ee36c83d8bb9b22d484>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type KOLStatisticsInput = {
  epochID: string;
  pageNumber?: number | null;
  pageSize?: number | null;
  query?: string | null;
};
export type KolStatisticsQuery$variables = {
  input: KOLStatisticsInput;
};
export type KolStatisticsQuery$data = {
  readonly kolStatistics: {
    readonly data: ReadonlyArray<{
      readonly accountID: string;
      readonly bdInCharge: ReadonlyArray<{
        readonly email: string;
        readonly firstName: string | null;
        readonly id: string;
        readonly lastName: string | null;
      }> | null;
      readonly commissionTier: number;
      readonly directCommissionRate: number;
      readonly directInviteTradingFee: string;
      readonly directInviteTradingVolume: string;
      readonly email: string;
      readonly epochEarning: string;
      readonly indirectCommissionRate: number;
      readonly indirectInviteTradingFee: string;
      readonly indirectInviteTradingVolume: string;
      readonly joinedDate: string;
      readonly name: string;
      readonly projectedEfficiencyRate: number;
      readonly projectedTotalCodes: number;
      readonly rank: number;
      readonly tier: string;
      readonly totalDirectPhase0Referrals: number;
      readonly totalDirectPhase1Referrals: number;
      readonly totalDirectReferrals: number;
      readonly totalIndirectPhase0Referrals: number;
      readonly totalIndirectPhase1Referrals: number;
      readonly totalIndirectReferrals: number;
      readonly totalPhase0Points: number;
      readonly totalPhase1Points: number;
      readonly totalPoints: number;
      readonly tradingVolume: string;
    }>;
    readonly total: number;
  };
};
export type KolStatisticsQuery = {
  response: KolStatisticsQuery$data;
  variables: KolStatisticsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "KOLStatisticsStatus",
    "kind": "LinkedField",
    "name": "kolStatistics",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "total",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "KOLStatistic",
        "kind": "LinkedField",
        "name": "data",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accountID",
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "joinedDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tier",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalDirectReferrals",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalIndirectReferrals",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalDirectPhase0Referrals",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalIndirectPhase0Referrals",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalDirectPhase1Referrals",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalIndirectPhase1Referrals",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalPoints",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalPhase0Points",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalPhase1Points",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rank",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "projectedTotalCodes",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "projectedEfficiencyRate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tradingVolume",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "directInviteTradingVolume",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "indirectInviteTradingVolume",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "directInviteTradingFee",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "indirectInviteTradingFee",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "directCommissionRate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "indirectCommissionRate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "commissionTier",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "epochEarning",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AdminPortalUser",
            "kind": "LinkedField",
            "name": "bdInCharge",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "KolStatisticsQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "KolStatisticsQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "0ec0a43a4d23705347dd171b88c8397c",
    "id": null,
    "metadata": {},
    "name": "KolStatisticsQuery",
    "operationKind": "query",
    "text": "query KolStatisticsQuery(\n  $input: KOLStatisticsInput!\n) {\n  kolStatistics(input: $input) {\n    total\n    data {\n      accountID\n      email\n      name\n      joinedDate\n      tier\n      totalDirectReferrals\n      totalIndirectReferrals\n      totalDirectPhase0Referrals\n      totalIndirectPhase0Referrals\n      totalDirectPhase1Referrals\n      totalIndirectPhase1Referrals\n      totalPoints\n      totalPhase0Points\n      totalPhase1Points\n      rank\n      projectedTotalCodes\n      projectedEfficiencyRate\n      tradingVolume\n      directInviteTradingVolume\n      indirectInviteTradingVolume\n      directInviteTradingFee\n      indirectInviteTradingFee\n      directCommissionRate\n      indirectCommissionRate\n      commissionTier\n      epochEarning\n      bdInCharge {\n        id\n        email\n        firstName\n        lastName\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3ed55bb5048eca30e76aed959875979f";

export default node;
