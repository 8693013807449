import { graphql } from 'relay-runtime'

import { ReviewKYBAttemptMutation } from './__generated__/ReviewKYBAttemptMutation.graphql'

export type { ReviewKYBAttemptMutation }

export const reviewKYBAttemptMutation = graphql`
  mutation ReviewKYBAttemptMutation($input: ReviewKYBAttemptInput!) {
    reviewKYBAttempt(input: $input) {
      data {
        ...on KYBAttempt {
          id
        }
      }
    }
  }
`
