import { EPaths, IRouterOptions } from 'src/router'
import { SettingDetail, Settings } from './components'

export enum ERoutes {
  SETTING = 'SETTING',
  SETTING_DETAIL = 'SETTING_DETAIL'
}

export const routes: IRouterOptions = [
  {
    path: EPaths.SETTING,
    name: ERoutes.SETTING,
    component: Settings,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: EPaths.SETTING_DETAIL,
    name: ERoutes.SETTING_DETAIL,
    component: SettingDetail,
    meta: {
      requiredAuth: true
    }
  }
]

export const SettingModule = {
  ERoutes,
  routes
}
