import { EPaths, IRouterOptions } from 'src/router'
import { AdminManagement } from './components'

export enum ERoutes {
  ADMIN_MANAGEMENT = 'ADMIN_MANAGEMENT'
}

export const routes: IRouterOptions = [
  {
    path: EPaths.ADMIN_MANAGEMENT,
    name: ERoutes.ADMIN_MANAGEMENT,
    component: AdminManagement,
    meta: {
      requiredAuth: true
    }
  }
]

export const AdminModule = {
  ERoutes,
  routes
}
