import { AddAddressToExclusionListMutation, ExcludedWalletsQuery, RemoveAddressFromExclusionListMutation, accountIdentityQuery, addAddressToExclusionListMutation, removeAddressFromExclusionListMutation } from 'src/graphql'
import { EdgeGraphqlService } from 'src/services'

export class ExcludedWalletApi {
  /**
   * SECTION: Queries
   */

  static excludedWallets(variables: ExcludedWalletsQuery['variables']) {
    return EdgeGraphqlService.queryAsPromise<ExcludedWalletsQuery>(
      accountIdentityQuery,
      variables
    ).then((response) => response.excludedWallets)
  }

  /**
   * SECTION: Mutations
   */

  static addToExcludedList(variables: AddAddressToExclusionListMutation['variables']) {
    return EdgeGraphqlService.mutationAsPromise<AddAddressToExclusionListMutation>(addAddressToExclusionListMutation, variables).then(
      (response) => response.addAddressToExclusionList
    )
  }

  static removeFromExcludedList(variables: RemoveAddressFromExclusionListMutation['variables']) {
    return EdgeGraphqlService.mutationAsPromise<RemoveAddressFromExclusionListMutation>(removeAddressFromExclusionListMutation, variables).then(
      (response) => response
    )
  }
}
