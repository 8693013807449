import { Button, Input, Modal, Radio } from 'antd'
import { ComponentProps, FC, SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { ExcludedWalletApi } from 'src/api/excluded-wallets'
import { ErrorMessage } from 'src/atoms'
import { ExcludedWalletEdge } from 'src/graphql'
import { useBehaviorMapper, useUnsubscribe, useValidation } from 'src/hooks'
import { AuthService, MessageService } from 'src/services'
import { EventUtils } from 'src/utils'
import { object, string } from 'yup'

enum SaveActions {
  ADD = 'add',
  REMOVE = 'remove'
}

const schema = object().shape({
  address: string().required('Address is required')
})

export const DialogSaveExcludedWallet: FC<Omit<
  ComponentProps<typeof Modal>,
  'onOk'
> & {
  btnProps?: ComponentProps<typeof Button>
  excludedWallet?: ExcludedWalletEdge | null
  onSaveSuccess?: () => any
  isRemove?: boolean
}> = ({
  btnProps,
  excludedWallet,
  isRemove,
  onSaveSuccess,
  ...props
}) => {
  const unsubscribe$ = useUnsubscribe()
  const user = useBehaviorMapper(AuthService.user$)
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<Parameters<typeof ExcludedWalletApi.addToExcludedList>[0]['input']>({
    address: ''
  })
  const [saveAction, setSaveAction] = useState<SaveActions>(SaveActions.ADD)

  const { validate, errors, reset } = useValidation({
    data: formData,
    schema
  })

  const getModalTitle = useCallback(() => {
    return excludedWallet
      ? 'Excluded'
      : isRemove
        ? 'Remove from exclusion list'
        : 'Add to exclusion list'
  }, [excludedWallet, isRemove])

  useEffect(() => {
    reset()
    setFormData({
      address: ''
    })

    if (props.open) {
      setSaveAction(isRemove ? SaveActions.REMOVE : SaveActions.ADD)

      if (excludedWallet) {
        setFormData({
          address: excludedWallet.walletAddress
        })
      }
    }
  }, [props.open, user, reset, isRemove, excludedWallet])

  const submit = useCallback(async (e: SyntheticEvent) => {
    EventUtils.preventDefault(e)

    const { isValid } = await validate()

    if (!isValid) {
      return
    }

    const isAdd = saveAction === SaveActions.ADD
    const promise = isAdd
      ? ExcludedWalletApi.addToExcludedList({ input: formData })
      : ExcludedWalletApi.removeFromExcludedList({ input: formData })
    setLoading(true)
    from(promise)
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          MessageService.error(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(() => {
        MessageService.info(`${isAdd ? 'Add' : 'Remove'} excluded wallet success`)
        onSaveSuccess?.()
      })
  }, [formData, unsubscribe$, validate, saveAction, onSaveSuccess])

  return (
    <>
      <Modal
        title={props.title || getModalTitle()}
        open={props.open}
        centered
        closable={!loading} // display X icon
        keyboard={false} // disable close on press ESC
        maskClosable={false} // disable close on click outside
        okButtonProps={{ disabled: loading }}
        cancelButtonProps={{ disabled: loading }}
        okText="Ok"
        cancelText="Cancel"
        onOk={submit}
        onCancel={props.onCancel}
      >
        {props.open && (
          <form onSubmit={submit} className="fx fx-column fx-extend gap-2">
            <input className="d-none" type="submit"/>

            <div className={excludedWallet ? 'd-none' : 'd-block'}>
              <Input
                required
                name="address"
                placeholder="Address"
                readOnly={loading}
                value={formData.address}
                status={errors.hasError('address') ? 'error' : undefined}
                onChange={(e) => setFormData((prev) => ({
                  ...prev,
                  address: e.target.value
                }))}
              />
              <ErrorMessage>{errors.getError('address')}</ErrorMessage>
            </div>

            {excludedWallet && (
              <Radio.Group value={saveAction} onChange={e => setSaveAction(e.target.value)}>
                <Radio value={SaveActions.ADD}>True</Radio>
                <Radio value={SaveActions.REMOVE}>False</Radio>
              </Radio.Group>
            )}

          </form>
        )}
      </Modal>
    </>
  )
}
