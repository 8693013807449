import { Table } from 'antd'
import { FC, useState } from 'react'
import { EMPTY, catchError, from, takeUntil } from 'rxjs'
import { PiiApi } from 'src/api'
import { TUserPii } from 'src/graphql'
import { useUnsubscribeEffect } from 'src/hooks'
import { KYCDocumentType, KYCDocumentTypeLabel } from 'src/interfaces'
import { MessageService, TzService } from 'src/services'
import { renderColumns } from './columns'

interface IProps {
  user?: TUserPii | null
}

export const Document: FC<IProps> = ({ user }) => {
  const [dataSource, setDataSource] = useState<Array<{
    'document-type'?: string | null
    'uploaded-by'?: string | null
    'uploaded-time'?: string | null
    comment?: string | null
    link?: string | null
  }>>([])

  useUnsubscribeEffect((unsubscribe$) => {
    setDataSource([])
    const kycAttemptID = user?.currentKyc?.id
    if (!kycAttemptID) {
      return
    }
    from(PiiApi.kycReviewDocuments({ input: { kycAttemptID } }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          MessageService.error(error)
          return EMPTY
        })
      )
      .subscribe((documents) => {
        setDataSource(documents?.map(
          (doc) => ({
            'document-type': KYCDocumentTypeLabel[doc.type as KYCDocumentType] || doc.type,
            'uploaded-by': doc.uploaderUserID,
            'uploaded-time': TzService.format(doc.createTime, 'HH:mm YYYY-MM-DD'),
            comment: doc.comment,
            link: doc.url
          })
        ) || [])
      })
  }, [user?.currentKyc?.id, user?.currentKyc?.kycReviewDocuments])

  return (
    <section className="fx fx-column fx-extend">
      <Table
        bordered
        rowKey="id"
        className="fx-extend"
        pagination={false}
        dataSource={dataSource}
        columns={renderColumns()}
        scroll={{ x: true }}
      />
    </section>
  )
}
