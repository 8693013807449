import { graphql } from 'relay-runtime'

import { UpdateKOLConfigMutation } from './__generated__/UpdateKOLConfigMutation.graphql'

export type { UpdateKOLConfigMutation }

export const updateKOLConfigMutation = graphql`
  mutation UpdateKOLConfigMutation($input: UpdateKOLConfigInput!) {
    updateKOLConfig(input: $input) {
      code
      message
    }
  }
`
