import { graphql } from 'relay-runtime'

import { CreateShortLinkMutation } from './__generated__/CreateShortLinkMutation.graphql'

export type { CreateShortLinkMutation }

export const createShortLinkMutation = graphql`
  mutation CreateShortLinkMutation($input: CreateShortLinkInput!) {
    createShortLink(input: $input) {
      code
      message
      shortLink {
        ...on ShortLink {
          id
          createTime
          updateTime
          articleID
          url
          activeStatus
          # article {
          #   ...on Article {
          #     id
          #     createTime
          #     updateTime
          #     url
          #     title
          #     description
          #     activeStatus
          #     shortLink {
          #       ...on ShortLink {
          #         id
          #         createTime
          #         updateTime
          #         articleID
          #         url
          #         activeStatus
          #       }
          #     }
          #   }
          # }
        }
      }
    }
  }
`
