export enum EPaths {
  BD_KOL_TRACKER = '/business-development/kol-tracker',
  BD_KOL_REWARD_DETAILS = '/business-development/kol-reward-details/:accountID',
  BD_KOL_CONFIGURATIONS_HISTORY = '/business-development/kol-configurations-history',
  BD_KOL_POINT_DISTRIBUTION = '/business-development/kol-point-distribution',
  BD_INSTITUTION_TRACKER = '/business-development/institution-tracker',
  BD_INST_CONFIGURATIONS_HISTORY = '/business-development/inst-configurations-history',
  BD_INST_PAYMENT_DETAILS = '/marketing/inst-payment-details/:accountID',
  BD_PERFORMANCE = '/marketing/bd-performance',
  BD_COMMISSION_RATE_HISTORY = '/marketing/bd-commission-rate-history'
}
