import { Button, Space } from 'antd'
import { Link } from 'react-router-dom'
import { TBusinessUserPiiOverview } from 'src/graphql'
import { ERoutes, generate } from 'src/router'
import { ClipboardService, TzService } from 'src/services'
import { KybUtils } from 'src/utils'

interface Actions {
  onGoDetail?: () => void
}

export const renderColumns = ({ onGoDetail }: Actions) => [
  {
    title: 'Account ID',
    dataIndex: 'accountID',
    key: 'accountID',
    className: 'break-all min-w-200',
    render: (accountID: string) => (
      <div
        className="pointer"
        onClick={() => ClipboardService.copy(accountID)}
      >
        {accountID}
      </div>
    )
  },
  {
    title: 'Business Name',
    dataIndex: 'fullLegalName',
    key: 'businessName',
    className: 'break-all min-w-150'
  },
  {
    title: 'Contact Email',
    dataIndex: 'email',
    key: 'email',
    className: 'break-all min-w-200'
  },
  {
    title: 'KYB status',
    dataIndex: 'kybStatus',
    key: 'kybStatus',
    render: (_: any, record: TBusinessUserPiiOverview) => KybUtils.renderKybStatusBadge(record.currentKyb?.state)
  },
  {
    title: 'Create time',
    dataIndex: 'createTime',
    key: 'CREATE_TIME',
    className: 'break-all min-w-200',
    render: (_: any, record: TBusinessUserPiiOverview) => record.createTime
      ? TzService.format(record.createTime, 'HH:mm YYYY-MM-DD')
      : '--'
  },
  {
    title: 'Last update time',
    dataIndex: 'updateTime',
    key: 'UPDATE_TIME',
    className: 'break-all min-w-200',
    render: (_: any, record: TBusinessUserPiiOverview) => record.updateTime
      ? TzService.format(record.updateTime, 'HH:mm YYYY-MM-DD')
      : '--'
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    render: (_: any, record: TBusinessUserPiiOverview) => (
      <Space>
        <Button
          size="small"
          type="link"
        >
          <Link
            to={generate([ERoutes.BUSINESS_KYB_DETAIL, {
              accountID: record.accountID
            }])}
            onClick={onGoDetail}
          >
            Detail
          </Link>
        </Button>
      </Space>
    )
  }
]
