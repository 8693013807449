import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Input, Table } from 'antd'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Pagination } from 'src/atoms'
import { ExcludedWalletEdge, ExcludedWalletsQuery, excludedWalletsQuery } from 'src/graphql'
import { useBehaviorMapper, useDidMountDebounce } from 'src/hooks'
import { DialogSaveExcludedWallet } from 'src/partials/dialog-save-excluded-wallet'
import { PaginationService } from 'src/services'
import { renderColumns } from './columns'

export const ExcludedWallets: FC = () => {
  const _paginationService = useMemo(() => new PaginationService<ExcludedWalletEdge>(excludedWalletsQuery), [])
  const loading = useBehaviorMapper(_paginationService.loading$)
  const dataSource = useBehaviorMapper(_paginationService.items$)

  const [filter, setFilter] = useState({ keyword: '' })
  const [modalSaveData, setModalSaveData] = useState<{
    open: boolean
    excludedWallet: ExcludedWalletEdge | null
    isRemove: boolean
  }>({
    open: false,
    excludedWallet: null,
    isRemove: false
  })

  const computedWhere = useMemo<ExcludedWalletsQuery['variables']['where']>(() => {
    const keywordTrimmed = filter.keyword.trim()
    return ({
      or: [
        ...(keywordTrimmed
          ? [
            { walletAddress: keywordTrimmed }
          ]
          : [])
      ]
    })
  }, [filter])

  const fresh = useCallback(
    (params?: Parameters<typeof _paginationService.fetch>[0]) => _paginationService.fetch(params),
    [_paginationService]
  )

  const handleSaveSuccess = useCallback(() => {
    setModalSaveData({ open: false, excludedWallet: null, isRemove: false })
    fresh()
  }, [fresh])

  useDidMountDebounce(() => {
    fresh({
      after: '',
      before: '',
      where: computedWhere
    })
  }, 500, [computedWhere, fresh])

  useEffect(() => {
    fresh({
      after: '',
      before: '',
      where: computedWhere
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <section className="fx fx-column fx-extend gap-2">
      <div className="fx fx-jc-space-between">
        <div className="fx fx-extend fx-ai-center gap-2 label-1">Excluded Wallets</div>
        <div className="fx fx-ai-center fx-jc-end gap-2">
          <Input
            allowClear
            placeholder="Search by wallet address"
            style={{ flex: '0 0 200px' }}
            readOnly={loading}
            value={filter.keyword}
            onChange={(e) => setFilter((prev) => ({
              ...prev,
              keyword: e.target.value
            }))}
          />
        </div>
      </div>

      <div className="fx fx-jc-end fx-ai-center gap-2">
        <Button
          type="primary"
          icon={<MinusOutlined/>}
          danger
          onClick={() => setModalSaveData({ open: true, excludedWallet: null, isRemove: true })}
        >
          Remove
        </Button>
        <Button
          type="primary"
          icon={<PlusOutlined/>}
          onClick={() => setModalSaveData({ open: true, excludedWallet: null, isRemove: false })}
        >
          Add
        </Button>
      </div>

      <Table
        bordered
        rowKey="id"
        className="fx-extend"
        loading={loading}
        dataSource={dataSource}
        columns={renderColumns({
          onEdit: (excludedWallet: ExcludedWalletEdge) =>
            setModalSaveData({
              open: true,
              excludedWallet,
              isRemove: !excludedWallet.isExcluded
            })
        })}
        scroll={{ x: true }}
      />

      <Pagination
        className="fx-as-end mt-3"
        disabled={loading}
        canPrev={_paginationService.canPrev}
        canNext={_paginationService.canNext}
        onPrev={() => _paginationService.prev({ where: computedWhere })}
        onNext={() => _paginationService.next({ where: computedWhere })}
      />

      <DialogSaveExcludedWallet
        open={modalSaveData.open}
        onCancel={() => setModalSaveData({ open: false, excludedWallet: null, isRemove: false })}
        excludedWallet={modalSaveData.excludedWallet}
        onSaveSuccess={handleSaveSuccess}
        isRemove={modalSaveData.isRemove}
      />
    </section>
  )
}
