import { graphql } from 'relay-runtime'

import { ArticlesQuery } from './__generated__/ArticlesQuery.graphql'

export type { ArticlesQuery }

export type TArticle = DeepWriteable<DeepExclude<TArrayItem<ArticlesQuery['response']['articles']['edges']>, null>['node']>

export type TShortLink = TArticle['shortLink']

export enum EArticleActiveStatus {
  ACTIVATED = 'activated',
  DEACTIVATED = 'deactivated'
}

export const articlesQuery = graphql`
  query ArticlesQuery(
    $after: Cursor
    $first: Int
    $before: Cursor
    $last: Int
    $where: ArticleWhereInput
  ) {
    articles(
      after: $after
      first: $first
      before: $before
      last: $last
      where: $where
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...on Article {
            id
            createTime
            updateTime
            url
            title
            description
            activeStatus
            shortLink {
              ...on ShortLink {
                id
                createTime
                updateTime
                articleID
                url
                activeStatus
              }
            }
          }
        }
      }
    }
  }
`
