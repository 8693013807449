import { Input, Table } from 'antd'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { ArticleApi } from 'src/api'
import { Pagination } from 'src/atoms'
import { ArticlesQuery, TArticle, articlesQuery } from 'src/graphql'
import { useBehaviorMapper, useDidMountDebounce, useUnsubscribe } from 'src/hooks'
import { DialogArticle } from 'src/partials'
import { EPaths } from 'src/router'
import { BreadcrumbService, MessageService, PaginationService } from 'src/services'
import { renderColumns } from './columns'

export const MarketingArticles: FC = () => {
  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = [{
      route: EPaths.MARKETING_ARTICLE,
      label: 'Articles'
    }]
    return () => {
      BreadcrumbService.items = []
    }
  }, [])

  const unsubscribe$ = useUnsubscribe()
  const _paginationService = useMemo(() => new PaginationService<TArticle>(articlesQuery), [])
  const loading = useBehaviorMapper(_paginationService.loading$)
  const dataSource = useBehaviorMapper(_paginationService.items$)

  const [filter, setFilter] = useState({ keyword: '' })

  const computedWhere = useMemo<ArticlesQuery['variables']['where']>(() => {
    const keywordTrimmed = filter.keyword.trim()
    return ({
      or: [
        ...(keywordTrimmed
          ? [
            { titleContainsFold: keywordTrimmed },
            { descriptionContainsFold: keywordTrimmed }
          ]
          : [])
      ]
    })
  }, [filter])

  const fresh = useCallback(
    (params?: Parameters<typeof _paginationService.fetch>[0]) => _paginationService.fetch(params),
    [_paginationService]
  )

  useDidMountDebounce(() => {
    fresh({
      after: '',
      before: '',
      where: computedWhere
    })
  }, 500, [computedWhere, fresh])

  useEffect(() => {
    fresh({
      after: '',
      before: '',
      where: computedWhere
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onRemove = useCallback((doc: TArticle) => {
    from(ArticleApi.remove({ id: doc.id }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          MessageService.error(error)
          return EMPTY
        }),
        finalize(() => {})
      )
      .subscribe(() => {
        MessageService.info('Delete Article success')
        fresh()
      })
  }, [fresh, unsubscribe$])

  return (
    <section className="fx fx-column fx-extend">
      <div className="fx fx-jc-space-between mb-2">
        <div className="fx fx-extend fx-ai-center gap-2">
          <Input
            allowClear
            placeholder="Search by title & description"
            style={{ flex: '0 0 250px' }}
            readOnly={loading}
            value={filter.keyword}
            onChange={(e) => setFilter((prev) => ({
              ...prev,
              keyword: e.target.value
            }))}
          />
        </div>

        <DialogArticle afterClose={(doc) => doc && fresh()}/>
      </div>

      <Table
        bordered
        rowKey="id"
        className="fx-extend"
        pagination={false}
        loading={loading}
        dataSource={dataSource}
        columns={renderColumns({
          onUpdate: (doc) => _paginationService.updateItem(doc),
          onRemove
        })}
        scroll={{ x: true }}
      />

      <Pagination
        className="fx-as-end mt-3"
        disabled={loading}
        canPrev={_paginationService.canPrev}
        canNext={_paginationService.canNext}
        onPrev={() => _paginationService.prev({ where: computedWhere })}
        onNext={() => _paginationService.next({ where: computedWhere })}
      />
    </section>
  )
}
