import { lazy } from 'react'
import { AdminModule } from 'src/modules/admin/routes'
import { AuthModule } from 'src/modules/auth/routes'
import { BusinessDevelopmentModule } from 'src/modules/business-development/routes'
import { ComplianceModule } from 'src/modules/compliance/routes'
import { CoreModule } from 'src/modules/core/routes'
import { MarketModule } from 'src/modules/csr/routes'
import { EmailTemplateModule } from 'src/modules/email-template/routes'
import { MarketingModule } from 'src/modules/marketing/routes'
import { SettingModule } from 'src/modules/setting/routes'
import { IRouterOptions } from './interface'

export const ERoutes = {
  ...AdminModule.ERoutes,
  ...AuthModule.ERoutes,
  ...CoreModule.ERoutes,
  ...EmailTemplateModule.ERoutes,
  ...MarketModule.ERoutes,
  ...MarketingModule.ERoutes,
  ...SettingModule.ERoutes,
  ...BusinessDevelopmentModule.ERoutes,
  ...ComplianceModule.ERoutes
}

export const routes: IRouterOptions = [
  ...AdminModule.routes,
  ...AuthModule.routes,
  ...CoreModule.routes,
  ...EmailTemplateModule.routes,
  ...MarketModule.routes,
  ...MarketingModule.routes,
  ...SettingModule.routes,
  ...BusinessDevelopmentModule.routes,
  ...ComplianceModule.routes,

  // last route handle 404 error
  {
    name: 'LAST_ROUTE',
    path: '*',
    component: lazy(() => import('src/modules/core/components/404').then((module) => ({ default: module.NotFound })))
  }
]

/**
 * IIFE
 * Revalidate the routes
 */
;(() => {
  const mergedNames: { [key: string]: boolean } = {}
  const mergedPaths: { [key: string]: boolean } = {}

  for (const { name, path } of routes) {
    if (mergedNames[name]) {
      throw new Error(`Duplicate route name: ${name}`)
    }
    if (mergedPaths[path]) {
      throw new Error(`Duplicate route path: ${path}`)
    }
    mergedNames[name] = true
    mergedPaths[path] = true
  }
})()
