import { EPaths, IRouterOptions } from 'src/router'
import { BusinessList, IndividualList } from './components'
import { BusinessDetail } from './components/business/detail'
import { BusinessUserInfo } from './components/business/user-info'
import { IndividualDetail } from './components/individual/individual-detail'

export enum ERoutes {
  CSR_BUSINESS = 'CSR_BUSINESS',
  CSR_BUSINESS_DETAIL = 'CSR_BUSINESS_DETAIL',
  CSR_BUSINESS_USER_DETAIL = 'CSR_BUSINESS_USER_DETAIL',
  CSR_INDIVIDUAL = 'CSR_INDIVIDUAL',
  CSR_INDIVIDUAL_DETAIL = 'CSR_INDIVIDUAL_DETAIL'
}

export const routes: IRouterOptions = [
  {
    path: EPaths.CSR_BUSINESS,
    name: ERoutes.CSR_BUSINESS,
    component: BusinessList,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: EPaths.CSR_INDIVIDUAL,
    name: ERoutes.CSR_INDIVIDUAL,
    component: IndividualList,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: EPaths.CSR_INDIVIDUAL_DETAIL,
    name: ERoutes.CSR_INDIVIDUAL_DETAIL,
    component: IndividualDetail,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: EPaths.CSR_BUSINESS_DETAIL,
    name: ERoutes.CSR_BUSINESS_DETAIL,
    component: BusinessDetail,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: EPaths.CSR_BUSINESS_USER_DETAIL,
    name: ERoutes.CSR_BUSINESS_USER_DETAIL,
    component: BusinessUserInfo,
    meta: {
      requiredAuth: true
    }
  }
]

export const MarketModule = {
  ERoutes,
  routes
}
