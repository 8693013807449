import { graphql } from 'relay-runtime'

import { RemoveAddressFromExclusionListMutation } from './__generated__/RemoveAddressFromExclusionListMutation.graphql'

export type { RemoveAddressFromExclusionListMutation }

export const removeAddressFromExclusionListMutation = graphql`
  mutation RemoveAddressFromExclusionListMutation($input: RemoveAddressFromExclusionListInput!) {
    removeAddressFromExclusionList(input: $input) {
      code
      message
    }
  }
`
