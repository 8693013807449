import { Button, Form, Input, Modal } from 'antd'
import { ComponentProps, FC, useCallback, useEffect, useState } from 'react'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { KolTrackerApi } from 'src/api'
import { TAccountKolRewards } from 'src/graphql'
import { useAsRef, useUnsubscribe } from 'src/hooks'
import { MessageService } from 'src/services'

type FieldType = {
  paidAmount: string
  comment: string
}

export const DialogAmountPaid: FC<Omit<
  ComponentProps<typeof Modal>,
  'onOk' | 'afterClose'
> & {
  accountKolReward?: TAccountKolRewards
  btnProps?: ComponentProps<typeof Button>
  afterClose?: (saved?: boolean) => any
}> = ({
  accountKolReward,
  btnProps,
  ...props
}) => {
  const unsubscribe$ = useUnsubscribe()
  const [loading, setLoading] = useState(false)
  const [invisible, setInvisible] = useState<boolean>(true)

  useEffect(() => {
    setInvisible(!props.open)
  }, [props.open])

  const afterCloseRef = useAsRef(props.afterClose)

  const [form] = Form.useForm()
  useEffect(() => {
    if (!invisible) {
      // form.setFieldsValue({ riskLevel: currentRiskLevel })
    }
  }, [invisible, form])

  const submit = useCallback(async (values: FieldType) => {
    if (
      !accountKolReward ||
      !accountKolReward.accountID ||
      !accountKolReward?.epoch?.epoch ||
      !values.paidAmount
    ) {
      return MessageService.error('Something went wrong!')
    }
    const input: Parameters<typeof KolTrackerApi.updateClientPaidAmount>[0]['input'] = {
      comment: values.comment,
      epoch: accountKolReward?.epoch?.epoch,
      id: accountKolReward?.accountID,
      paidAmount: Number(values.paidAmount)
    }

    setLoading(true)
    from(KolTrackerApi.updateClientPaidAmount({ input }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          MessageService.error(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(() => {
        MessageService.info('Paid amount changed successfully!')
        setInvisible(true)
        form.resetFields()
        afterCloseRef.current?.(true)
      })
  }, [accountKolReward, form, afterCloseRef, unsubscribe$])

  return (
    <>
      <Button
        type="link"
        {...btnProps}
        onClick={() => setInvisible(false)}
      >
        {props.children || 'Edit amount paid'}
      </Button>

      <Modal
        title={props.title || 'Edit amount paid'}
        open={!invisible}
        centered
        closable={!loading} // display X icon
        keyboard={false} // disable close on press ESC
        maskClosable={false} // disable close on click outside
        okButtonProps={{ disabled: loading }}
        cancelButtonProps={{ disabled: loading }}
        okText="Ok"
        cancelText="Cancel"
        onOk={() => form.submit()}
        onCancel={() => {
          setInvisible(true)
          form.resetFields()
        }}
      >
        {!invisible && (
          <Form
            form={form}
            name="change-amount-paid"
            labelCol={{ span: 7 }}
            style={{ maxWidth: 600 }}
            onFinish={submit}
            autoComplete="off"
          >
            <Form.Item<FieldType>
              label="Amount Paid"
              name="paidAmount"
              rules={[{ required: true }]}
            >
              <Input pattern="\d+"/>
            </Form.Item>
            <Form.Item<FieldType>
              label="Comment"
              name="comment"
            >
              <Input.TextArea/>
            </Form.Item>
          </Form>
        )}
      </Modal>
    </>
  )
}
