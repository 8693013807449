import { graphql } from 'relay-runtime'

import { BusinessAccountIdentityQuery } from './__generated__/BusinessAccountIdentityQuery.graphql'

export type { BusinessAccountIdentityQuery }

export type TBusinessUserPii = DeepWriteable<BusinessAccountIdentityQuery['response']['businessAccountIdentity']['data']>

export const businessAccountIdentityQuery = graphql`
  query BusinessAccountIdentityQuery($accountID: String!) {
    businessAccountIdentity(accountID: $accountID) {
      data {
        ...on BusinessAccountIdentity {
          id
          createTime
          updateTime
          accountID
          fullLegalName
          doingBusinessAsName
          businessType
          incorporationCountry
          dateOfIncorporation
          incorporationNumber
          website
          email
          phone
          legalAddress
          primaryAddress
          finalState
          currentKybAttemptID
          currentKyb {
            ...on KYBAttempt {
              id
              createTime
              updateTime
              businessAccountIdentityID
              state
              documents {
                ...on KYBDocument {
                  id
                  createTime
                  updateTime
                  kybAttemptID
                  uploaderUserID
                  uploaderRole
                  url
                  type
                  fileName
                  comment
                }
              }
              actionLogs {
                ...on KYBActionLog {
                  id
                  createTime
                  updateTime
                  kybAttemptID
                  subjectUserID
                  subjectType
                  subjectEmail
                  prevAttemptState
                  currentAttemptState
                  action
                  message
                  data
                }
              }
            }
          }
          userIdentities {
            ...on UserIdentity {
              id
              createTime
              updateTime
              userID
              accountID
              email
              firstName
              lastName
              kycReviewState
              currentKyc {
                ...on KYCAttempt {
                  state
                  kycTags {
                    ...on KYCTag {
                      id
                      value
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
